<template>
    <div class="createur-article-temporaire">
        <div class="createur-contenu">
            <v-text-field v-model="titre" flat solo class="titre" hide-details label="Titre de votre article pour le retrouver facilement"></v-text-field>
            <!-- permettra la selection bibliotheque ou dépot du fichier principal mais aussi des inserts publicitaires (voir composant equivalent dans le portail) -->
            <definition-fichier
                :pages-min="article.pages_min"
                :pages-max="article.pages_max"
                :reference="article.reference"
                :titre="titreArticle"
                v-model="fichier_id"
                v-on:close="etapeTerminee"
                :lister-temporaire-seulement=true
                :creer-en-temporaire=true
                type="source"
                key="defini-principal"
            ></definition-fichier>
        </div>
    </div>
</template>

<script>
import Api from "../../api/api";
import DefinitionFichier from "../fichiers/DefinitionFichier";
import {mapGetters, mapState} from "vuex";

export default {
    name: "CreateurArticleTemporaire",
    components: {
        DefinitionFichier
    },
    data() {
        return {
            urlCreeArticleTemporaire:    process.env.VUE_APP_API_URL + '/article/sauvetemporaire',
            etape: 1,
            titre: '',
            fichier_id: null,
            insertionsSansFichiers: [],
        }
    },
    props: {
        article: {
            type: Object,
            required: true,
            validator: (article) => {
                return ('id', 'reference', 'titre', 'description') in article;
            }
        },
    },
    computed: {
        ...mapGetters([
            'isLogged'
        ]),
        ...mapState([
            'dialogLogin'
        ]),
        pagesMin () {
            if (!this.article || !this.article.pages_min) return 0;
            return this.article.pages_min;
        },
        pagesMax () {
            if (!this.article || !this.article.pages_max) return 0;
            return this.article.pages_max;
        },
        titreArticle () {
            return this.titre.length > 0 ? this.titre : this.article.titre;
        },
        nombreEtapes () {
            return 1 + this.insertionsSansFichiers.length;
        }
    },
    watch: {
        dialogLogin: {
            handler () {
                if (!this.isLogged && !this.dialogLogin)
                    this.retour();
            }
        }
    },
    methods: {
        etapeTerminee () {
            if (this.fichier_id) {
                if(this.etape < this.nombreEtapes) {
                    this.etape++;
                } else {
                    this.sauvegarde();
                }
            }
        },
        sauvegarde () {
            if(this.fichier_id === null) return;

            let data = {
                id: this.article.id,
                titre: this.titreArticle,
                fichier_id: this.fichier_id,
            }
            if (typeof this.article.assemblage === 'object') data.assemblage = this.article.assemblage;

            Api.post(this.urlCreeArticleTemporaire, data)
                .then(article_tempo => {
                    this.$emit('change', article_tempo.reference);
                    this.$emit('close');
                })
                .catch(err => {
                    this.$store.commit('addSnackMessage', {msg: err});
                })
        },
        retour () {
            if (this.intervalActualisation) clearInterval(this.intervalActualisation);
            if (window.history.length >= 1) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        extraitInsertionsSansFichiers() {
            if (typeof this.article.assemblage !== 'object' || !Object.hasOwnProperty.call(this.article.assemblage,'elements')) return;
            this.insertionsSansFichiers = this.article.assemblage.elements.filter(element => element.fichier === null);
        },
    },
    mounted () {
        this.extraitInsertionsSansFichiers();
    }
}
</script>

<style scoped lang="scss">
.createur-article-temporaire {
    @media(max-width:1280px) and (min-width:935px) {
        width:calc(100vw - 480px)
    }
    width:min(100vw, 800px);
    max-width:800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    & .createur-titre {
        text-align: center;
        font-size: 1.2em;
        font-weight: 600;
        line-height: 40px;
    }
    & .createur-contenu {
        flex-grow: 1;
        & .createur-description {
            white-space: pre-wrap;
        }
    }
    & .createur-actions {
        padding: 20px;
        display: flex;
        flex-direction: row;
    }
    & .titre {
        border: 2px solid #a0a0a0;
        border-radius: 16px 16px 0 0;
        background-color:white;
        color: #e0e0e0;
    }
}
</style>