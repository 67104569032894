<template>
    <v-img :src="dataUrl" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
        <template v-slot:placeholder>
            <slot name="placeholder"></slot>
        </template>
    </v-img>
</template>
<script>
    import ImageMixin from './image';
    export default {
        name: "VImgPortail",
        mixins: [
            ImageMixin
        ],
    }
</script>
