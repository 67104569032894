<template>
  <div class="perso-qrcode" :style="styleCalculeCadre">
    <canvas class="dessin"></canvas>
  </div>
</template>

<script>
import CodeBarreMixin from "./mixins/codebarre";
import QRCode from "qrcode";
export default {
  name: "ElementQrcode",
  mixins: [
    CodeBarreMixin
  ],
  computed: {
    niveauCorrection () { //utilise la même méthode de calcul de correction selon la densité utilisé dans Element.php
      if (this.element === undefined || this.value.data === undefined || this.data === undefined) return "H";
      let res_possibles = ['H', 'Q', 'M', 'L'],
          aire = Math.round(Math.sqrt(this.value.position.l * this.value.position.h)),
          caracteres_par_mm_carre = this.data.length / aire,
          resolution = Math.max(Math.min(Math.trunc(Math.floor(caracteres_par_mm_carre)), 3), 0);
      return res_possibles[resolution]
    }
  },
  watch: {
    echelle (val, old) {
      if (val !== old) this.rendu();
    },
    data (val, old) {
      if (val !== old) this.rendu();
    },
  },
  methods: {
      //Composant de QRCODE: https://github.com/soldair/node-qrcode
      rendu() {
        if (this.data) QRCode.toCanvas(this.canvas, this.data, { errorCorrectionLevel: this.niveauCorrection, width: this.cadreEnPixel.largeur, margin: 0 });
      }
  },
  mounted() {
    this.rendu();
  }
}
</script>

<style scoped lang="scss">
.perso-qrcode {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>