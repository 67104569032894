<template>
    <div class="definition-fichier">
        <div class="definition-fichier-main">
            <zone-depot v-if="progress >= -1 && progress <= 100"  @file-submit="gereFichier">
                <div class="uploader" :style="uploaderClassStyle">
                    <div class="upload-bloc">
                        <div class="upload-border" :class="dragstyle">
                            <div v-if="(progress >= 0 && progress <= 100)" class="progression" :style="'height: ' + progress + '%;'"></div>
                            <div class="upload-text" v-html="texteUpload"></div>
                        </div>
                    </div>
                </div>
            </zone-depot>

            <div class="info-file" :style="uploaderClassStyle" v-else-if="analyse.totalpages !== 0">
                <div class="grille no-margin">
                    <div class="ligne">
                        <div class="cellule ajustee avec-bordure">
                            <label>Sens :</label>
                            {{ analyse.orientation }}
                        </div>
                        <div class="cellule ajustee avec-bordure">
                            <label>Nombre de<br>pages&#xA0;</label>
                            {{ analyse.totalpages }}
                        </div>
                        <div class="cellule ajustee avec-bordure">
                            <label>Page(s)<br>noire(s)&#xA0;</label>
                            {{ analyse.pagesnoires }}
                        </div>
                        <div class="cellule ajustee avec-bordure">
                            <label>Page(s)<br>couleur(s)&#xA0;</label>
                            {{ analyse.pagescouleurs }}
                        </div>
                        <div class="cellule ajustee avec-bordure">
                            <label>Format fini<br>(L x H)</label>
                            {{ analyse.largeur_rogne }} x {{ analyse.hauteur_rogne }}mm
                        </div>
                    </div>
                    <div class="ligne" v-if="bordsPerdusIncoherents">
                        <div class="cellule">
                            <template v-if="pagesSansBordsPerdus.length > 20">
                                <span class="alert alert-warning">Il y a {{ pagesSansBordsPerdus.length }} pages sans bord perdu dans votre document</span>
                            </template>
                            <template v-else-if="pagesSansBordsPerdus.length === 1">
                                <div>Il n'y a pas de bord perdu dans la page {{ pagesSansBordsPerdus.join(', ') }}</div>
                            </template>
                            <template v-else>
                                <div class="alert alert-warning">
                                    <p>
                                        Il n'y a pas de bord perdu dans les pages suivantes :
                                    </p>
                                    <div>
                                        {{ pagesSansBordsPerdus.join(', ') }}
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="ligne">
                        <div class="cellule">
                            <v-btn text @click.stop="reinitialiseUpload">Changer de fichier</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="definition-fichier-actions">
            <btn-theme class="btn-action" @click.stop="sauve" :disabled="!valide">Confirmer</btn-theme>
            <btn-theme class="btn-action" v-if="ignorable" @click.stop="close">Ignorer</btn-theme>
        </div>
    </div>
</template>

<script>
// composant permettant de creer un fichier ou de selectionner un fichier existant

const ANALYSE_DEFAULT = {
    fichier_id: '',
    hash: '',
    fonds: {},
    nomtemp: '',
    orientation: '',
    pagescouleurs: 0,
    pagesnoires: 0,
    surface: 0,
    largeur: 0,
    largeur_rogne: 0,
    hauteur: 0,
    hauteur_rogne: 0,
    redistille: false,
    totalpages: 0
};

const DEPOSEZ_FICHIER = "Déposez votre document PDF dans cette zone";
const ICONE_CHARGEMENT = "<v-icon>"+ mdiLoading +"</v-icon>";

import Api from "../../api/api";
import BtnTheme from "../theme/BtnTheme";
import ZoneDepot from "../zonedepot/ZoneDepot";
import { mdiLoading } from "@mdi/js";

export default {
    name: "DefinitionFichier",
    components: {
        BtnTheme,
        ZoneDepot
    },
    data() {
        return {
            fichier: null,
            urlListeFichiersTemporaires:    process.env.VUE_APP_API_URL + '/fichier/listetemporaires',
            urlListeFichiersPermanent:      process.env.VUE_APP_API_URL + '/fichier/liste',
            urlCreeFichier:                 process.env.VUE_APP_API_URL + '/fichier/creer',
            idFichierSelectionne: null,
            onglet: 0,
            urlAnalyse:     process.env.VUE_APP_API_URL + '/fichier/analyse',
            urlEffaceTmp:   process.env.VUE_APP_API_URL + '/fichier/effacetmp',
            files: [],

            //fichier: null,
            dragstyle: "",
            progress: -1,
            analyse: Object.assign({}, ANALYSE_DEFAULT),
            texteUpload: ''
        }
    },
    props: {
        pagesMax: {
            type: Number,
            default: 0,
            validator: val => Number.isInteger(val)
        },
        pagesMin: {
            type: Number,
            default: 0,
            validator: val => Number.isInteger(val)
        },
        type: {
            type: String,
            require: true,
            validator: type => type.length > 0
        },
        reference: {
            type: String,
            default: '',
        },
        titre: {
            type: String,
            default: '',
        },
        listerTemporaireSeulement: {
            type: Boolean,
            default: true
        },
        creerEnTemporaire: {
            type: Boolean,
            default: true
        },
        ignorable: {
            type: Boolean,
            default: false
        },
        /*
        */
        validator: {
            type: Function,
            default: files => new Promise(r => r(files))
        },
        silent: {
            default: undefined
        },
        componentWidth: {
            type: [Number,String],
            default: 600
        },
        componentHeight: {
            type: [Number,String],
            default: 260
        },
        visible: {
            type: Boolean,
            default: true
        },
        uploadText: {
            type: String,
            default: DEPOSEZ_FICHIER
        }
    },
    computed: {
        urlListeFichier() {
            return this.listerTemporaireSeulement   ? this.urlListeFichiersTemporaires + '/' + this.type
                                                    : this.urlListeFichiersPermanent + '/' + this.type
        },
        fichierTeleverse() {
           return this.onglet === 0 && this.fichier !== null;
        },
        fichierSelectionne() {
            return this.onglet === 1 && this.idFichierSelectionne !== null;
        },
        valide() {
            return this.fichierTeleverse || this.fichierSelectionne;
        },
        bordsPerdusIncoherents () {
            //créé un tableau des dimensions de bords perdus en mm
            const taillesBordsPerdus = Object.keys(this.analyse.fonds).map(Number),
                nombreDeTailles = taillesBordsPerdus.length;

            //le tableau ne comporte aucune dimensions => problème
            if (nombreDeTailles <= 0) return true;

            //certaines pages n'ont pas de bord perdus. Ce n'est valide qui si c'est le cas de toutes les pages
            let plusPetitTaille = Math.min(...taillesBordsPerdus);
            if (plusPetitTaille === 0) return nombreDeTailles !== 1;

            return false;
        },
        pagesSansBordsPerdus () {
            return this.analyse.fonds[0] || [];
        },
        uploaderClassStyle () {
            return {
                //width: typeof this.componentWidth === 'number'
                //    ? this.componentWidth + 'px'
                //    : this.componentWidth,
                height: typeof this.componentHeight === 'number'
                    ? this.componentHeight + 'px'
                    : this.componentHeight
            };
        }
    },
    methods : {
        sauve() {
            if(!this.valide) return;

            //fichier existant selectionné
            //if (this.onglet === 1 && this.idFichierSelectionne) return this.close(this.idFichierSelectionne);

            //fichier téléversé
            let data = new FormData();
            if (this.reference.length > 0) data.append('reference',this.reference);
            if (this.titre.length > 0) data.append('titre',this.titre);
            data.append('hash', this.analyse.hash);
            data.append('type', this.type);
            if (this.analyse.fichier_id > 0) data.append('id', this.analyse.fichier_id);
            data.append('fichier', this.analyse.nomfichier);
            data.append('fichiertmp', this.analyse.nomtemp);
            data.append('temporaire', 1);
            Api.post(this.urlCreeFichier, data)
                .then(contenu => {
                    this.close(contenu.id);
                })
                .catch(err => {
                    this.$store.commit('addSnackMessage', {msg: err});
                });
        },
        close (fichier_id = null) {
            this.$emit('input', fichier_id)
            this.$emit('close', null);
        },
        reinitialiseUpload () {
            this.effaceFichierTemporaireSurServeur();
            this.$emit('reset');
            this.$emit('input', null);
            this.$emit('file', null);
            this.progress = -1; // Si progress est négatif, la progression n'est pas affichée et l'ajout de fichier est apparent
            this.analyse = Object.assign({}, ANALYSE_DEFAULT); // On vide l'analyse (puisqu'on est sur un potentiellement nouveau fichier)
            this.fichier = null; // On vide le fichier qu'on avait stocké
            let fileInput = this.$refs.fileInput;
            if (fileInput) fileInput.files = null;
            if (this.uploadText) {
                this.texteUpload = this.uploadText; // Et on remet le message d'ajout
            }
        },
        effaceFichierTemporaireSurServeur () {
            if (!this.analyse || !Object.hasOwnProperty.call(this.analyse, 'nomtemp') || this.analyse.nomtemp === '') return;
            let data = new FormData();
            data.append('fichiertmp', this.analyse.nomtemp);
            Api.post(this.urlEffaceTmp, data);
        },
        gereFichier (files) {
            let fichier;
            if (files.length > 0) { // Sinon, le paramètre est le fichier
                fichier = files[0];
            }
            else {
                this.$emit('abort', "Aucun fichier sélectionné")
                return;
            }
            this.texteUpload = ICONE_CHARGEMENT;
            // On lance l'analyse locale du fichier
            this.verifieTypeFichier(fichier, result => {
                if (result) { // Si c'est bien un pdf, on envoi au serveur pour procéder à l'analyse complète
                    this.analyseFichier(fichier)
                } else {
                    this.$emit('abort', "Fichier invalide");
                    if (this.silent !== "")
                        this.$store.commit('addSnackMessage', {msg: "Veuillez déposer un fichier PDF valide."});
                    this.reinitialiseUpload();
                }
            });
        },
        verifieTypeFichier (fichier, callback) {
            try {
                let reader = new FileReader();
                const regexPDF = new RegExp("%PDF-1.[0-7]");
                reader.onload = e => { // Quand le reader aura fini, on appelle le callback en lui passant le résultat de l'analyse locale
                    callback(!!e.target.result.match(regexPDF))
                };
                reader.readAsBinaryString(fichier); // On lance la lecture du fichier
            }
            catch(e) {
                this.reinitialiseUpload()
            }
        },
        analyseFichier (fichier) {
            // Callback pour afficher la progression
            let progressCallback = e => {
                this.progress = Math.round((e.loaded / e.total) * 100);
                this.$emit('progress', this.progress);
                if(this.progress > 99) {
                    this.texteUpload = "<span>" + ICONE_CHARGEMENT + " Analyse en cours</span>"
                } else {
                    this.texteUpload = "<span>" + this.progress + '%</span>'
                }
            };

            // Construction des données envoyées
            let data = new FormData();
            data.append('file', fichier);

            // Envoi des données
            this.$emit('upload');
            Api.post(

                this.urlAnalyse,
                data,
                {
                    onUploadProgress: progressCallback
                }
            ).then(response => {
                    Object.assign(this.analyse, response);
//                            this.progress = -2
                    // Tests complémentaires
                    tests: {
                        // Si le fichier ne respecte pas les contraintes de pages
                        if ( (this.pagesMin && this.analyse.totalpages < this.pagesMin) || (this.pagesMax && this.analyse.totalpages > this.pagesMax) ) {
                            let msg = "Le fichier téléchargé n'est pas utilisable pour ce modèle.\nCe modèle est prévu pour un fichier comportant ";
                            if (this.pagesMin && this.pagesMax) {
                                msg += "entre " + this.pagesMin + " et " + this.pagesMax;
                            } else if (this.pagesMin) {
                                msg += "minimum " + this.pagesMin
                            } else {
                                msg += "maximum " + this.pagesMax
                            }
                            msg += " pages. Or, le fichier téléchargé comporte " + this.analyse.totalpages + " pages.";

                            this.$store.commit('addSnackMessage', {msg: msg});
                            break tests;
                        }
                        // Si l'utilisateur annule une des actions, on sort du label "tests" et on évite le return
                        if (this.analyse.fichier_id && this.silent !== "") { // Si le fichier existe déjà dans la base
                            if (!confirm("Le fichier existe déjà. Voulez-vous quand-même garder ce fichier ?")) {
                                this.$emit('abort', "Fichier déjà existant.");
                                break tests;
                            }
                        }
                        if (this.analyse.redistille && this.silent !== "") { // Si le fichier est redistillé
                            if(!confirm("Le fichier était endommagé ou protégé, nécessitant donc un traitement supplémentaire afin de le réparer. Souhaitez-vous tout de même conserver ce fichier ?")) {
                                this.$emit('abort',"Fichier endommagé ou protégé.");
                                break tests;
                            }
                        }
                        // Si on ne souhaite pas redéposer un fichier, on sort de la fonction pour éviter la réinitialisation
                        this.progress = -2;
                        this.fichier = fichier;
                        this.$emit('input', this.analyse);
                        this.$emit('file', this.fichier);
                        return
                    }
                    // Si un des messages est annulé, la progression redevient négative, et le message d'upload est réinitialisé
                    this.reinitialiseUpload();
                },
                function (error) {
                    this.$emit('abort',error.message);
                    if(this.silent !== "")
                        this.$store.commit('addSnackMessage', {msg: error.message});
                    this.reinitialiseUpload();
                }
            )
        },
    },
    mounted () {
        window.beforeunload = this.effaceFichierTemporaireSurServeur;
        if(!('resetUpload' in window)) {
            window.resetUpload = () => {
                this.reinitialiseUpload()
            }
        }
        if (this.uploadText) {
            this.texteUpload = this.uploadText;
        }
    }
}
</script>

<style scoped lang="scss">
    .definition-fichier {
        flex-grow: 1;
        & .definition-fichier-actions {
            display: flex;
            flex-direction: row-reverse;
            & .btn-action {
                margin: 5px;
            }
        }
        & .definition-fichier-main {
            & .onglets {
                flex-grow: 0;
            }
            .container-onglets {
                & .upload {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }

            .uploader {
                /* Conteneur de l'upload */
                & .upload-bloc {
                    margin-bottom: 10px;
                    width: 100%;
                    height: 100%;
                    :hover {
                        background-color: #D6D7E5 !important;
                        //border-color: #3B7FED !important;
                        cursor: alias;
                    }
                    background-color:white;

                    /* Bordure de l'upload */
                    & .upload-border {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-width: 0 2px 2px 2px;
                        border-color: #a0a0a0;
                        border-style: dashed;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        //background-color: #f4f4f4 !important;

                        .drag {
                            background-color: #D6D7E5;
                            border-color: #3B7FED !important;
                            cursor: copy;
                        }

                        & .progression {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            opacity: 0.3;
                            background-color: #3B7FED;
                        }

                        & .btn-upload {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        >.img {
                            border: 1px solid #f2f2f2;
                        }

                        /* Texte de l'upload */
                        & .upload-text {
                            text-align:center;
                            display: flex;
                            font-family: Roboto, sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                            color: #ABA9A9;
                            line-height: 40px;

                            > * {
                                left:0;
                                right:0;
                                margin: auto;
                            }

                            > i, .upload-text > span > i {
                                display: block;
                                font-size:50px;
                            }
                        }
                    }

                }
            }

            .no-margin {
                margin-top: 0;
                margin-bottom: 0;
            }

            .info-file {
                padding: 1px;
                background-color:white;
                //background-color: #f5f5f5;
                //border: 2px solid #e3e3e3;
                border-width: 0 2px 2px 2px;
                border-color: #a0a0a0;
                border-style: solid;
                display: flex;
                flex-direction: row;
                & .grille {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    width: 100%;

                    /* FLEXBOXES */
                    & .ligne {
                        flex-grow: 1;
                        //height: calc(100% / 3);

                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        width: 100%;

                        > .cellule {
                            flex-grow: 1;

                            text-align: center;
                            padding: 10px;
                            margin: auto 5px;
                            top: 2px;
                            bottom: 2px;

                            > label {
                                font-weight: bold;
                                display: block;
                            }
                            > button {
                                margin: 10px;
                            }
                        }
                        > .cellule.ajustee{
                            height: 90px;

                        }
                        > .cellule.avec-bordure {
                            border: 1px solid #e3e3e3;
                            border-radius: 4px;
                            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
                        }
                        > .cellule.left {
                            text-align: left;
                        }
                        > .cellule.right {
                            text-align: right;
                        }
                    }

                }


            }

        }
    }
</style>