<template>
    <!--
     Cette vue unifie tous les types de produits (articles, nomenclature, personnalisés, à dépot de fichier) en une interface

     Sur la gauche, la vignette pleine taille de l'article et sur le coté, selon la nature du produit:
              - le créateur d'article temporaire pour les produits nécéssitant la selection/dépot de fichiers
              - la personnalisation pour les produit personnalisé.
              - l'édition de quantité pour les nomenclatures

    Et, quelques soit le type du produit
            - le selecteur de quantite
            - En bas se trouve deux boutons: ajouter au panier et retour qui ramenerons à la boutique à l'emplacement précédent cette fenetre

    Interactivité:
            - la vignette est masquée par les éditeurs de dépot de fichier et de personnalisation pour gagner en place
            - Le selecteur de quantité est masqué par tout éditeur actif (evite les incohérences et gagne de la place)
            - le bouton ajout n'est actif que lorsque les conditions sont reunies (quantité valides, editeurs inactif)

    -->
    <div class="produit">
        <div class="produit-content">
            <div class="entete">
                <div class="fil-ariane">
                    <router-link to="/">Accueil</router-link>
                    <template v-if="groupe">
                        <span> > </span>
                        <router-link :to="'/produit/'+ groupe.reference">{{ groupe.titre }}</router-link>
                    </template>
                    <template v-if="element">
                        <span> > </span>
                        <router-link :to="'/produit/'+ (groupe ? groupe.reference + '/'+ element.reference : element.reference)" custom v-slot="{ navigate }">
                            <h2 @click="navigate">{{ element.titre }}</h2>
                        </router-link>
                    </template>
                </div>
            </div>
            <div v-if="modulePersonnalisationVisible" class="creation-article-temporaire">
                <personnalisation-pdf v-if="element.personnalisation.type === 'customform'" :article="element" v-on:close="fermeEditeurPersonnalisation" v-on:change="chargeElement" v-model="personnalisationDefinie"></personnalisation-pdf>
                <personnalisation-acroform v-if="element.personnalisation.type === 'acroform'" :article="element" v-on:close="fermeEditeurPersonnalisation" v-on:change="chargeElement" v-model="personnalisationDefinie"></personnalisation-acroform>
            </div>
            <template v-else-if="ready">
                <div class="illustration-produit">
                    <template v-if="createurArticleTemporaireVisible">
                        <createur-article-temporaire v-if="createurArticleTemporaireVisible" :article="element" v-on:close="fermeCreateurArticleTemporaire" v-on:change="chargeElement"></createur-article-temporaire>
                    </template>
                    <div class="produit-bat d-flex align-center justify-center" v-else-if="moduleBATVisible">
                        <viewer-b-a-t :article="element" v-model="batValide" :disponible="batDisponible"></viewer-b-a-t>
                    </div>
                    <template v-else>
                        <template v-if="element.flipbook">
                            <img-media :default-image-url="elementVignetteUrl" :titre="element.titre" :medias="listeMedias" class="flipbook" @click="flipbook" />
                        </template>
                        <template v-else>
                            <img-media :default-image-url="elementVignetteUrl" :titre="element.titre" :medias="listeMedias" />
                        </template>
                    </template>
                </div>
                <div class="produit-infos">
                    <v-card class="produit-details" elevation="2">
                        <v-card-title class="titre">
                            <h3 v-if="groupe">Personnalisez votre article</h3>
                            <h3 v-else-if="afficherTotaux">Résumé de votre article</h3>
                        </v-card-title>
                        <v-card-text class="pb-0">
                            <div v-if="groupe">
                                <div v-if="groupe" class="pt-3">
                                    <template v-for="variante in variantes">
                                        <v-select
                                            v-model.number="variante.valeur"
                                            :disabled="variante.length <= 1 || createurArticleTemporaireVisible"
                                            :items="variante.liste"
                                            item-text="libelle"
                                            item-value="valeur"
                                            :label="variante.nom"
                                            :key="variante.nom"
                                            @change="changeVariante"
                                        >
                                        </v-select>
                                    </template>
                                </div>
                            </div>
                            <template v-if="varianteExistante">
                                <template v-if="stockVisible && element.categorie === 'article'">
                                    <div class="produit-stock caracteristique" v-if="element.disponibilite > 0 && element.disponibilite !== 999999">
                                        <span class="libelle">Stock disponible :</span> {{ element.disponibilite }}
                                    </div>
                                    <div class="produit-stock caracteristique" v-else-if="element.disponibilite <= 0">
                                        <span class="libelle rouge">En rupture de stock</span>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="produit-inexistant">
                                    Il n'existe aucun produit répondant à ces critères.
                                </div>
                            </template>
                            <template v-if="afficherTotaux">
                                <div class="caracteristique"><span class="libelle">Caractéristiques:</span> {{ element.caracteristiques.map(el => el.libelle).join(', ') }}</div>
                                <div class="caracteristique"><span class="libelle">Pages noires:</span> {{ element.pages_noires }}</div>
                                <div class="caracteristique"><span class="libelle">Pages couleurs:</span> {{ element.pages_couleurs }}</div>
                            </template>
                            <div class="unites-totales catacteristique" v-if="element.lot > 1 && element.lot !== ''"><span>soit {{ quantite * element.lot }} unités</span></div>
                            <div class="poids-total caracteristique" v-if="afficherTotaux"><span class="libelle">Poids total:</span> {{ formatPoids(poidsTotal || 0) }}</div>
                            <div class="prix-total caracteristique" v-if="afficherTotaux"><span class="libelle">Prix unitaire hors taxes:</span> {{ formatMonetaire(prixUnitaire, portail.decimales_prix) }}</div>
                            <div class="prix-total caracteristique" v-if="afficherTotaux"><span class="libelle">Prix total hors taxes:</span> {{ formatMonetaire(prixTotal, portail.decimales_prix) }}</div>
                            <div class="delai-fabrication caracteristique" v-if="comportePrixAPalier(element) && palierElement.delai">
                                <span class="libelle">Délai:</span> {{ palierElement.delai }} jours ouvrés
                            </div>
                            <div class="caracteristique" v-if="!prixCalcules">Article en cours de calculs</div>
                            <v-text-field v-if="moduleBATVisible" v-model="quantite" label="quantite" type="number" :rules="reglesQuantite"></v-text-field>
                            <div v-if="!ajoutPanierPossible" class="invalidation-ajout">
                                <span v-if="!elementCharge">Element non chargé</span>
                                <span v-if="!varianteExistante">Variante inexistante</span>
                                <template v-if="!quantiteValide">
                                    <span v-if="quantite < quantiteMinimaleCalculee(element)">Quantité minimale non atteinte</span>
                                    <span v-if="(element.lot * quantite) > element.disponibilite">Quantité supérieure au stock disponible</span>
                                    <span v-if="element.quantite_maximale && quantite > element.quantite_maximale">Quantité supérieure au maximum autorisé</span>
                                </template>
                            </div>
                        </v-card-text>
                        <v-card-actions class="pt-0">
                            <div>
                                <div class="produit-reference caracteristique"><span class="libelle">À partir de : </span>{{ prix }}</div>
                                <div class="produit-reference caracteristique" v-if="mentionsNbPages"><span class="libelle">Fichier pdf : </span>{{ mentionsNbPages }}</div>
                                <div class="produit-reference caracteristique"><span class="libelle">Référence : </span>{{ element.reference }}</div>
                            </div>
                            <v-spacer></v-spacer>

                            <btn-theme v-if="!estArticleAPersonnaliser(element)&&!estArticleACompleter(element)" :disabled="!ajoutPanierPossible" @click.stop="ajoutAuPanier">Ajouter au panier</btn-theme>
                            <btn-theme v-if="estArticleAPersonnaliser(element)" @click.stop="ouvreEditeurPersonnalisation" :disabled="!varianteExistante">Déposer mon fichier PDF</btn-theme>
                            <template v-else-if="estArticleACompleter(element)">
                                <btn-theme v-if="!createurArticleTemporaireVisible" @click.stop="ouvreCreateurArticleTemporaire" :disabled="!varianteExistante">Déposer mon fichier PDF</btn-theme>
                                <btn-theme class="red" v-else @click.stop="fermeCreateurArticleTemporaire">Retour personnalisation</btn-theme>
                            </template>
                        </v-card-actions>
                    </v-card>
                </div>
                <v-card class="produit-description" v-if="element.description" v-html="element.description"></v-card>
            </template>
            <div class="attente" v-else>
                Chargement en cours, veuillez patienter...
            </div>
        </div>
    </div>
</template>
<script>
import MonnaieMixin from "../mixins/monnaie";
import PoidsMixin from "../mixins/poids";
import ElementMixin from "../mixins/element";
import {mapGetters, mapActions} from "vuex";
import _ from "lodash";
import Api from "../api/api";
import CreateurArticleTemporaire from "../components/articles/CreateurArticleTemporaire";
import PersonnalisationPdf from "../components/pdf/PersonnalisationPdf";
import PersonnalisationAcroform from "../components/articles/PersonnalisationAcroform";
import ViewerBAT from "../components/pdf/ViewerBAT";
import {empty} from '../helpers';
import ImgMedia from "../components/theme/ImgMedia";
import BtnTheme from "../components/theme/BtnTheme";

export default {
    name: "Produit",
    components: {
        ImgMedia,
        BtnTheme,
        CreateurArticleTemporaire,
        PersonnalisationPdf,
        PersonnalisationAcroform,
        ViewerBAT,
    },
    mixins: [
        MonnaieMixin,
        PoidsMixin,
        ElementMixin,
    ],
    data: () => ({
        ready: false,
        urlVignette:    process.env.VUE_APP_API_URL + '/image/vignette/grande',
        urlGetElement:  process.env.VUE_APP_API_URL + '/boutique/element',
        quantite: 1,
        prix_auto: 0,
        prix_finitions: 0,
        stockVisible: false, // Récupère la valeur dans le portail pour afficher ou non les stocks
        createurArticleTemporaireVisible: false,
        modulePersonnalisationVisible: false,
        depotFichierVisible: false,
        nomenclatureEnCoursEdition: false,  //si cette variable est a true, il ne faut pas accepter l'ajout au panier
        moduleBATVisible: false,
        elementPrecedent: '',               //element sérialisé lors d'un précédent chargement (pour comparaison des changements)
        reference: '',
        variante:'',
        intervalActualisation: null,
        delaiActualisation: 5000,
        groupe: null,
        element: {},                        //objet contenant l'élément après modification (ajout de fichier, edition de nomenclature ou personnalisation)
        variantes: [],                      //liste des variantes affichées
        batValide: false,
        personnalisationDefinie: false,
        navbarVisible: true,
        stepRetour: 1, //nombre de pas en arrière nécéssaire pour revenir à la liste des produits.
        batDisponible: false,
    }),
    computed: {
        ...mapGetters([
            'panier',
            'portail',
            'isLogged'
        ]),
        mentionsNbPages() {
            if (!this.element.pages_min && !this.element.pages_max)
                return null;
            else if (this.element.pages_min && !this.element.pages_max)
                return 'à partir de '+ this.element.pages_min + ' page'+ (this.element.pages_min > 1 ? 's':'');
            else if (!this.element.pages_min && this.element.pages_max)
                return 'jusq`\'à '+ this.element.pages_max + ' pages';
            else if (this.element.pages_min === this.element.pages_max)
                return 'de '+ this.element.pages_min +' page'+ (this.element.pages_min > 1 ? 's':'');
            else
                return 'de '+ this.element.pages_min +' à '+ this.element.pages_max +' pages';
        },
        prix () {
            //if (this.estArticleACompleter(this.element)) return 'Prix suivant contenu';
            if (this.prixMinimum(this.element) > 0) return this.enTTC(this.prixMinimum(this.element));
            return ' ';
        },
        referenceEtTitre () {
            return this.element.reference + " - " + this.titre
        },
        titre () {
            let titre = '';
            if (this.element.lot !== 1 && !this.element.prix_lot) titre += 'Lot de ' + this.element.lot + ' ';
            return titre + (this.element.contenu !== undefined ? this.element.titre +' ( '+ this.element.contenu.intitule + ' )' : this.element.titre);
        },
        varianteExistante() {
            return this.articlesFiltres.length > 0 || this.groupe === null;
        },
        //etat de l'élément
        elementCharge() {
            return typeof this.element === 'object' && Object.hasOwnProperty.call(this.element, 'reference');
        },
        batCree () {
            return this.flipbookDisponible(this.element);
        },
        prixCalcules () {
            return this.prixEtPoidsCalcules(this.element);
        },
        quantiteValide () { //la quantité doit respecter la valeur seuil mais aussi la disponibilité si stock
            return this.elementCharge &&
                this.quantite >= this.quantiteMinimaleCalculee(this.element) &&
                (this.element.lot * this.quantite) <= this.element.disponibilite &&
                !(this.element.quantite_maximale && this.quantite > this.element.quantite_maximale);
        },
        editeurActif() {
            return this.nomenclatureEnCoursEdition
                || this.createurArticleTemporaireVisible
                || this.modulePersonnalisationVisible;
        },
        ajoutPanierPossible() {
            return this.editeurActif === false              //un editeur est actif => pas d'ajout sans risque d'être incoherent entre l'affichage et l'objet mis au panier
                && this.elementCharge
                && this.varianteExistante
                && this.quantiteValide
                && (this.estPicking(this.element) || this.batValide || this.estElementCatalogue(this.element));
        },
        afficherTotaux () {
            return this.prixCalcules && this.prixTotal > 0 && this.poidsTotal > 0
        },
        //calculs
        prixUnitaire () {
            return this.element && this.element.prix > 0
                ? this.element.prix
                : '-'
        },
        poidsUnitaire () {
            return this.element && this.element.poids > 0
                ? this.element.poids
                : '-';
        },
        prixTotal () {
            let prix = (this.estNomenclatureEditable(this.element))
                ? this.prixUnitaire
                : this.prixUnitaireCalcule(this.element, this.quantite);
            prix *= Math.abs(this.quantite);

            return prix > 0
                ? this.arrondiMonetaire(prix)
                : '-';
        },
        poidsTotal () {
            let poids = (this.estNomenclatureEditable(this.element))
                ? this.poidsUnitaire
                : this.element.poids;

            poids *= Math.abs(this.quantite);

            return poids > 0
                ? Math.round(poids)
                : '-';
        },
        elementVignetteUrl () {
            return this.element.vignette ? (this.urlVignette + '/' + encodeURI(this.element.vignette) + '?libelle=' + encodeURI(this.element.reference)) : null;
        },
        listeMedias() {
            let ret = [];
            if (this.element.medias)
                ret = ret.concat(this.element.medias)
            if (this.groupe && this.groupe.medias)
                ret = ret.concat(this.groupe.medias);

            return ret;
        },

        tagsVarianteSelectionnee () {
            if (this.variantes.length === 0) return [];
            return this.variantes
                .map(variante => variante.valeur)
                .filter(valeur => valeur !== null);
        },
        articlesFiltres () {
            if (this.groupe === null) return [this.element];
            let elements = Object.assign([], this.groupe.articles);
            elements = elements.filter(element => {
                return this.tagsVarianteSelectionnee.every(caracteristique => {
                    return element.caracteristiques.find(carac => carac.id === caracteristique);
                });
            });
            return elements;
        },
        palierElement () {
            if (!this.comportePrixAPalier(this.element)) return null;
            return this.palierAtteint(this.element, Math.max(this.quantiteMinimaleCalculee(this.element), this.quantite));
        },
        reglesQuantite() {
            return [
                v => !!v || "Quantité requise",
                v => (v && v < this.quantite_minimale) || !this.quantite_minimale || "La quantité minimale est de "+ this.quantite_minimale,
                v => (v && v > this.quantite_maximale) || !this.quantite_minimale || "La quantité minimale est de "+ this.quantite_maximale
            ];
        },
    },
    watch: {
        ready (ready) {
            if (ready) this.$nextTick(() => this.focusEtSelectionneQuantite());
        }
    },
    methods: {
        ...mapActions([
            'ajoutPanier',
            'setNavBar'
        ]),
        hasHistory () {
            return window.history.length > 2;
        },
        retour () {
            if (this.intervalActualisation) clearInterval(this.intervalActualisation);
            if (window.history.length >= this.stepRetour) {
                this.$router.go(-this.stepRetour);
            } else {
                this.$router.push('/');
            }
        },
        ouvreEditeurPersonnalisation () {
            this.desactiveActualisation();
            this.modulePersonnalisationVisible = true;
            // Masque la navbar de force
            this.navbarVisible = this.navbar;
            this.setNavBar(false);
            window.scrollTo(0, 0);
        },
        fermeEditeurPersonnalisation() {
            this.modulePersonnalisationVisible = false;
            this.setNavBar(this.navbarVisible);
        },
        ouvreCreateurArticleTemporaire() {
            //if (this.isLogged)
            //    this.depotFichierVisible = true;
            //else
            //    this.$router.push('/connexion');
            this.desactiveActualisation();
            this.createurArticleTemporaireVisible = true;
        },
        fermeCreateurArticleTemporaire() {
            this.createurArticleTemporaireVisible = false;
        },
        ajoutAuPanier () {
            if (!this.elementCharge) return;
            //le panier contient l'élement completé par la quantité définie (le lot est déjà inclu)
            let element = Object.assign({}, this.element);
            element.quantite = this.quantite;
            this.ajoutPanier(element);
            this.retour();
        },
        changeLot () {
            if (!this.elementCharge) return;
            this.element.prix = this.element.prix_lot[this.element.lot];

            this.element.poids = this.element.lot * this.element.poidsunitaire;
            this.element.epaisseur = this.element.lot * this.element.epaisseurunitaire;
        },
        activeActualisation() {
            this.desactiveActualisation();
            this.intervalActualisation = setInterval(this.actualiseElement, this.delaiActualisation);
        },
        desactiveActualisation() {
            if (this.intervalActualisation) clearInterval(this.intervalActualisation);
        },
        chargeElement (reference, actualiseURl = true) {
            this.reference = reference;
            if (actualiseURl){
                this.$router.push('/produit/' + reference);
                this.stepRetour++;
            }
            this.ready = false;
            this.activeActualisation();
            this.actualiseElement();
        },
        actualiseElement () {
            return Api.get(this.urlGetElement + '/' + encodeURIComponent(this.reference))
                .then(reponse => {
                    let element;
                    if (reponse.type === 'groupe') {
                        this.desactiveActualisation();
                        this.groupe = Object.assign({}, reponse);

                        this.listeVariantes(reponse.variantes);

                        let variante = this.$route.params.variante;
                        if (variante) element = this.groupe.articles.find(el => el.reference === variante);
                        else          element = this.selectionneElement();
                    } else {
                        this.groupe = null;
                        element = reponse;
                        if (!this.batValide) this.moduleBATVisible = !this.estElementPermanent(element);
                    }
                    this.definiElement(element);
                    this.variantesDepuisElement(element);
                    this.ready = true;
                });
        },
        /**
         * Depuis un element, saisie les caractéristiques correspondantes dans les select, (Donc l'inverse de la logique de la page)
         *
         * @param element
         */
        variantesDepuisElement(element) {
            element.caracteristiques.forEach(el => {
                this.variantes.forEach(vari =>  {
                    let tmp = vari.liste.find(v => v.valeur === el.id)
                    if (tmp) vari.valeur = tmp.valeur;
                });
            });
        },
        listeVariantes (liste) { // constitue la liste des variantes
            let variantes = [];

            for (let rubrique in liste) {
                if (!Object.hasOwnProperty.call(liste, rubrique)) continue;
                let valeurs = [],
                    valeurInitiale = null;

                for (let i = 0; i < liste[rubrique].length; i++) {
                    let variante = liste[rubrique][i];
                    valeurs.push({
                        valeur: variante.id,
                        libelle: variante.libelle,
                        ordre: variante.ordre || 0
                    });
                }
                valeurs = _.sortBy(valeurs, ['ordre']);
                valeurInitiale = valeurs[0].valeur;

                variantes.push(
                    {
                        nom: rubrique,
                        valeur: valeurInitiale,
                        liste: valeurs
                    }
                )
            }
            this.variantes = variantes;
        },
        definiElement(element) {

            //si la préparation est terminée, on desactive l'actualisation toute les 5 secondes
            if (this.preparationTerminee(element)) {
                this.desactiveActualisation();
                this.batDisponible = true;
            }
            let elementJson = JSON.stringify(element);
            if (this.elementPrecedent === elementJson) return; //rien n'a changé
            this.elementPrecedent = elementJson;

            //si il s'agit d'une actualisation, il faut récupérer le lot selectionné par l'utilisateur
            let lot = this.element.lot !== undefined ? this.element.lot : null;
            if (lot === null) lot = element.lot; //si chargement initial, on prend celui de l'élement chargé
            element.poids = lot * element.poidsunitaire;
            if (lot && lot > 1) {
                element.lot = lot;
                if (element.prix_lot && typeof element.prix_lot[lot] !== 'undefined' && !empty(element.prix_lot[lot])) {
                    element.prix = element.prix_lot[lot];
                }
            }

            this.element = Object.assign({}, element);
            if (isNaN(this.quantite) || this.quantite < this.quantiteMinimaleCalculee(element)) this.quantite = this.quantiteMinimaleCalculee(element);
        },
        selectionneElement()
        {
            if (this.articlesFiltres.length === 0) return this.groupe.articles[0]; //faux, si aucun article ne correponds, il faut l'indiquer
            return this.articlesFiltres[0];
        },
        changeVariante() {
            let variante = this.selectionneElement();
            this.definiElement(variante);
        },
        focusEtSelectionneQuantite () {
            let qteInput = this.$refs.quantiteInput;
            if (typeof qteInput === 'undefined') return;

            let input = qteInput.$el.querySelector('input');
            if (typeof input === 'undefined') return;

            input.focus();
            input.select();
        },
        flipbook() {
            if (this.element.flipbook) this.$router.push('/liseuse/' + this.element.id);
        }
    },
    mounted () {
        let reference = this.$route.params.reference || this.$attrs.reference;
        this.chargeElement(reference, false);
        this.stockVisible = this.portail['afficher_stock'];
    },
    beforeDestroy() {
        this.desactiveActualisation();
    }
}
</script>
<style scoped lang="scss">
.creation-article-temporaire {
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
}
.produit {
    width: 100%;
    background-color:#F5F5F5;

    display: flex;
    flex-direction: column;

    & .produit-content {
        & .produit-description {
            font: normal normal normal 20px/27px Nunito;
            margin-top:10px;
            width:100%;
            background-color:white;
            text-align:justify;
            padding:24px 20px;
        }

        flex-grow: 1;
        margin: 0 auto;
        max-width:1280px;
        @media(max-width:820px) {
            padding: 12px 0;
        }
        padding:12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        & > .illustration-produit {
            @media(min-width:1280px) {
                margin: 0.5em;
                min-width:450px;
            }
            & .produit-bat {
                display: flex;
                justify-content: center;
                min-height: 400px;
                min-width: min(400px, 100vw);
            }
        }

        & > .produit-infos {
            @media(min-width:460px) {
                min-width:440px;
            }
            min-width:100vw;
            flex-basis:0;
            display: flex;
            flex-direction: column;
            text-align: left;
            .produit-details {
                display: flex;
                flex-direction: column;
                min-width: 300px;
                margin: 0.5em;

                & > .titre {
                    flex-direction: column-reverse;
                    padding:6px;
                    background-color:#F7F7F7;
                    font: normal normal 600 24px/40px Nunito;
                }
                .produit-titre {
                    text-align: center;
                    font-size: 1.2em;
                    font-weight: 600;
                    line-height: 40px;
                }
                & .produit-inexistant {
                    color: red;
                    font-size: 1.2em;
                    font-weight: 600;
                    padding: 12px;
                    text-align: center;
                    background-color: pink;
                    border: 1px solid #e6a1ad;
                    border-radius: 5px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
                & .produit-details-spacer {
                    flex-grow: 1;
                }
                & .produit-modifie {
                    display: flex;
                    justify-content: center;
                    .btn-modification{
                        margin: 5px
                    }
                }
            }
            .produit-quantite {
                margin: 0.5em;
                padding: 1em;
                .select-quantite {
                    display: flex;
                    flex-direction: row-reverse;
                    .select-label {
                        line-height: 40px;
                        padding-right: 10px;
                    }
                    .select-selecteur {
                        width: 150px;
                        max-width: 100%;
                    }
                    select {
                        cursor:pointer;
                        display: block;
                        padding: 5px;
                        border: 1px solid lightgray;
                        border-radius: 5px;
                        width: 150px;
                        max-width: 100%;
                        box-sizing: border-box;
                        appearance: auto !important;

                    }
                    select:focus {
                        outline: none;
                    }
                }
                .input-quantite {
                    display: flex;
                    flex-direction: row-reverse;
                    .input-label {
                        line-height: 40px;
                        padding-right: 10px;
                    }
                    input {
                        cursor: text;
                        display: block;
                        padding: 5px;
                        border: 1px solid lightgray;
                        border-radius: 5px;
                        width: 150px;
                        max-width: 100%;
                        box-sizing: border-box;
                        appearance: auto !important;
                    }
                    input:focus {
                        outline: none;
                    }
                }
                .invalidation-ajout > span {
                    color:red;
                }
            }

            .produit-reference {
                margin-left:6px;
                font: normal normal normal 12px Nunito;
                color: #535353;
            }
            .caracteristique {
                line-height: 18px;
            }
            .libelle {
                font-weight: 600;
            }
            .unites-totales {
                font-style: italic;
                font-size: 0.8em;
            }
        }
    }
    .rouge {
        color:red;
    }
    & .produit-actions {
        padding: 20px;
        display: flex;
        flex-direction: row;
    }
}
.attente {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}
</style>
<!-- Les styles scopés ne fonctionnent pas sur les sous classes des composants-->
<style lang="scss">
.produit-description img {
    max-width:100%;
}
.flipbook {
    .produit-image-principale {
        cursor: pointer;
    }
}
</style>