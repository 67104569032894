import ElementMixin from "./element";
export default {
    mixins: [
        ElementMixin,
    ],
    computed: {
        cadre () { //exprimé en mm, uniquement définie pour rendre plus lisible les variables
            let dimensions = {
                x:        this.value.position.x,
                y:        this.value.position.y,
                largeur:  this.value.position.l,
                hauteur:  Math.max(this.value.position.h, this.hauteurMinimale),
            };
            switch (this.value.position.calign) {
                case 'M':   // la position y de la cellule est par rapport à son millieu
                    dimensions.y -= (dimensions.hauteur / 2);
                    break;
                case 'B':   // la position y de la cellule est par rapport à son bas
                    dimensions.y -= dimensions.hauteur;
                    break;
                //non pris en charge => les positions relatives au baseline de la font.
                default:
            }
            return dimensions;
        },
        cadreEnPouce () { //exprimé en pouce, pour determiner si la densité de l'image est suffisante
            return {
                largeur:  this.mm2inch(this.value.position.l),
                hauteur:  this.mm2inch(this.value.position.h),
            };
        },
        cadreEnPixel () { //exprimé en pixel, uniquement définie pour rendre plus lisible les variables
            return {
                x:        Math.round(this.cadre.x * this.echelle),
                y:        Math.round(this.cadre.y * this.echelle),
                largeur:  Math.round(this.cadre.largeur * this.echelle),
                hauteur:  Math.round(this.cadre.hauteur * this.echelle),
            };
        },
        styleCalculeCadre () {
            let style = {
                left:   this.cadreEnPixel.x.toString() + 'px',
                top:    this.cadreEnPixel.y.toString() + 'px',
                width:  this.cadreEnPixel.largeur.toString() + 'px',
                height: this.cadreEnPixel.hauteur.toString() + 'px'
            }
            //gestion de la rotation du bloc texte => TCPDF gère la rotation par le coin supérieur gauche, en sens trigonométrique (inverse du CSS)
            if (this.value.position.angle) {
                style["transform-Origin"] =  "top left";
                style.transform = 'rotate(-' + this.value.position.angle + 'deg)';
            }
            return style;
        },
    },
    watch: {

    },
    methods: {

    },
    mounted() {
        this.canvas = this.$el.querySelector('canvas.dessin');
        this.context = this.canvas.getContext("2d");
    }
}