import Api from "../../../../api/api";
import {mapGetters} from "vuex";

export default {
    data: () => ({
        listeFonts: {},
        urlListeFonts:  process.env.VUE_APP_API_URL + '/personnalisation/fonts',
        urlChargeFont:  process.env.VUE_APP_API_URL + '/media/reference',
    }),
    computed: {
        ...mapGetters([
            'bearer'
        ]),
    },
    methods: {
        async chargeFont(nomtcpdf, fontfamily) { //retourne une promesse après le chargement de la font permettant de chainer un traitement d'initialisation
            //let blob = await Api.getBlob(this.urlChargeFont + '/' + nomtcpdf);
            //let url = window.URL.createObjectURL(blob);
            let url = this.urlChargeFont + '/' + nomtcpdf + '?Bearer=' + this.bearer;
            // noinspection JSUnresolvedFunction
            let ff = new FontFace(fontfamily, "url('" + url  + "')",{});
            //console.log(`FontFace with font name "${fontfamily}" was just declared (time: ${document.timeline.currentTime})`);
            return await ff.load().then(ffChargee => {
                document.fonts.add(ffChargee);
                return Promise.resolve(ffChargee);
            }).catch(() => {
                this.$store.commit('addSnackMessage', {msg: `Impossible de charger la police "${fontfamily}"`});
            });
        },
        async chargefontsNecessaires (elements) {
            let listeFonts =  await Api.get(this.urlListeFonts);
            this.listeFonts = Object.assign({}, listeFonts);
            if (!elements || elements.length === 0) return
            let listeFontsUtilisees = elements
                .filter(element => element.type === 'texte' || element.type === 'bloc') // ne conserve que les élements texte
                .map(element => element.font.name)                                      // retourne le nom de la font
                .filter((v, i, a) => a.indexOf(v) === i);                               // retire les doublons
            //console.log('Fonts utilisees', listeFontsUtilisees);
            let listeChargement = [];
            for (let i = 0; i < listeFontsUtilisees.length; i++) {
                let nomtcpdf = listeFontsUtilisees[i];
                if (!Object.hasOwnProperty.call(listeFonts, nomtcpdf)) continue;
                listeChargement.push(this.chargeFont(nomtcpdf, listeFonts[nomtcpdf]));
            }
            return Promise.all(listeChargement);
        }
    }
}

