import Api from "../../api/api";

export default {
    data: () => ({
        pdf: null,
        pdfDoc: null,
        loaded: true,
        loading: false,
        pdfDocSeulement: false,
    }),
    computed: {
        totalPages() {
            return this.pdf ? this.pdf.numPages : 0;
        },
    },
    methods: {
       readFileAsync(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();

                reader.onload = () => {
                    resolve(reader.result);
                };

                reader.onerror = reject;

                reader.readAsArrayBuffer(file);
            })
        },
        async initPdfJS() {
            let pdfjs = await import(/* webpackChunkName: 'pdfjs-dist' */ 'pdfjs-dist');
            pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min.js?id=' + new Date().getTime();
            return pdfjs;
        },
        async chargeDepuisUrl(url) {
            let blob = await Api.getBlob(url);
            return this.chargeDepuisBlob(blob);
        },
        async chargeDepuisFichierTeleverse(file) {
            return await this.chargeDepuisBlob(file);
        },
        async chargeDepuisBlob(blob) {
           let buffer = await this.readFileAsync(blob);
           let {PDFDocument} = await import(/* webpackChunkName: 'pdf-lib' */ 'pdf-lib');
           if (this.pdfDocSeulement) {
               this.pdfDoc = await PDFDocument.load(buffer);
           } else {
                let pdfjs = await this.initPdfJS();
                [this.pdfDoc, this.pdf] = await Promise.all([
                    PDFDocument.load(buffer),
                    pdfjs.getDocument({data: buffer}).promise
                ]);
           }
           return Promise.resolve(true);
        },
        async chargeDepuisBase64(base64datas) {
            let datasBinaires = atob(base64datas);
            return await this.chargeDepuisBlob(datasBinaires);
        },
        async chargement(source, type = 'url') {
            switch (type) {
                case 'url':
                    return await this.chargeDepuisUrl(source);
                case 'blob':
                    return await this.chargeDepuisBlob(source);
                case 'file':
                    return await this.chargeDepuisFichierTeleverse(source);
                case 'base64':
                    return await this.chargeDepuisBase64(source);
            }
            return Promise.reject(null);
        },
        chargementPDF(source, type = 'url') {
            this.loaded = false;
            this.loading = true;
            this.chargement(source, type).then(() => {
                this.loaded = true;
                this.loading = false;
                this.$emit('loaded', this.pdf)
            })
            .catch(err => {
                this.pdf = null;
                this.loaded = false;
                this.loading = false;
                this.$emit('loaded', null)
                this.pdf = null;
                this.$store.commit('addSnackMessage', {msg: err});
            });
        },
        async chargementPDFSynchrone(source, type = 'url') {
            this.loaded = false;
            this.loading = true;
            await this.chargement(source, type);
            this.loaded = true;
            this.loading = false;
            this.$emit('loaded', this.pdf);
        }
    }
}