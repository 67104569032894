<template>
    <div class="panel-bat">
        <div class="header-bat">
            <div class="controle-bat" v-if="totalFeuilles > 1">
                <div class="infos-bat text-center">Format réel du document: {{ format.largeur}} x {{ format.hauteur }} mm</div>
                <div class="controles">
                    <btn-theme small @click.stop="feuillePrecedente" :disabled="feuille < 2">
                        <v-icon>{{ mdiChevronLeft }}</v-icon>
                    </btn-theme>
                    <btn-theme small @click.stop="feuilleSuivante" :disabled="feuille > totalFeuilles">
                        <v-icon>{{ mdiChevronRight }}</v-icon>
                    </btn-theme>
                </div>
            </div>
        </div>
        <div :class="classe" class="bat" ref="bat" :style="style">
            <div class="phrase-attente" v-if="!disponible">
                <div>
                Chargement du B.A.T. en cours, merci de patienter...
                <br/>
                <v-progress-circular
                    indeterminate
                    color="#24D2FD"
                ></v-progress-circular>
                </div>
            </div>
            <div class="page" :style="stylePage" v-if="pageRectoVisible" >
                <div v-show="type === 'carte' && this.ready">Recto:</div>
                <rendu-page :pdf="pdf" :pdf-doc="pdfDoc" :page="pageRecto" v-model="recto" :trim="trim" :largeur="dimensionPage.width" :hauteur="dimensionPage.height"></rendu-page>
            </div>
            <div class="spacer-page" v-if="pageVersoVisible && pageRectoVisible"></div>
            <div class="page" :style="stylePage" v-if="pageVersoVisible">
                <div v-show="type === 'carte' && this.ready">Verso:</div>
                <rendu-page :pdf="pdf" :pdf-doc="pdfDoc" :page="pageVerso" v-model="verso" :trim="trim" :largeur="dimensionPage.width" :hauteur="dimensionPage.height"></rendu-page>
            </div>
        </div>

        <v-spacer></v-spacer>
        <div class="mention">
            <v-checkbox dense v-model="valide" :label="labelValidation" error-messages="Attention: Ceci vous engage vis à vis du document imprimé."></v-checkbox>
        </div>
    </div>
</template>

<script>
import LoaderMixin from './loader';
import RenduPage from "./RenduPage";
import ConversionUniteMixin from "../../mixins/conversion/unite";
import BtnTheme from "../theme/BtnTheme";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

export default {
    name: "ViewerBAT",
    components: {
        BtnTheme,
        RenduPage
    },
    mixins: [
        LoaderMixin,
        ConversionUniteMixin
    ],
    data: () => ({
        feuille: 1,
        urlBAT:     process.env.VUE_APP_API_URL + '/article/bat/id',
        zoom: -1,
        recto: null,
        verso: null,
        trim: false,
        valide: false,
        dimensionsLecteur: null,

        mdiChevronLeft,
        mdiChevronRight
    }),
    props: {
        article: {
            type: Object,
            default: null,
            validator: (article) => {
                return article['id'] !== undefined && article['fichier_id'] !== undefined;
            },
        },
        livret: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        },
        disponible: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        totalPages() {
            return this.pdf ? this.pdf.numPages : 0;
        },
        totalFeuilles() {
            return this.totalPages / 2;
        },
        pageRecto() {
            let page = (this.feuille - 1) * 2;
            return page + 1;
        },
        labelValidation() {
            return this.disponible ? "Je valide ce B.A.T."
                                   : "Je valide sans attendre le B.A.T.";
        },
        pageVerso() { //dans le cas d'un livre, le verso correpond au recto - 1, dans une carte, le recto + 1
            let decalage =  this.type === 'livret' ? -1 : 1;
            return this.pageRecto + decalage;
        },
        pageRectoVisible() {
            return this.pageRecto >= 1 && this.pageRecto <= this.totalPages;
        },
        pageVersoVisible() {
            return this.pageVerso >= 1 && this.pageVerso <= this.totalPages;
        },
        orientation() {
            return this.format.largeur > this.format.hauteur ? 'paysage' : 'portrait';
        },
        type() {
            return this.livret || this.totalPages > 2 ? 'livret' : 'carte'
        },
        classe() {
            return this.type + ' ' + this.orientation;
        },
        style() {
            return this.dimensionPage ? 'height:'+ this.dimensionPage.height +'px' : ('height:350px');
        },
        stylePage () {
            return "width:" + this.dimensionPage.width + 'px;'+ this.style;
        },
        ready() {
            return (this.recto && this.recto.ready) || (this.verso && this.verso.ready);
        },
        format() {
            let largeur = 0.0,
                hauteur = 0.0;
            if (this.recto !== null && this.pageRectoVisible) {
                largeur = this.recto.trimbox.largeur;
                hauteur = this.recto.trimbox.hauteur;
            } else if (this.verso !== null && this.pageVersoVisible) {
                largeur = this.verso.trimbox.largeur;
                hauteur = this.verso.trimbox.hauteur;
            }

            return {
                largeur: Math.round(largeur * 10) / 10,
                hauteur: Math.round(hauteur * 10) / 10
            }
        },

        dimensionPage() {
            let width, height;
            if(this.dimensionsLecteur === null) return null;
            //if (this.orientation === 'paysage') {
            //    width = this.dimensionsLecteur.width;
            //    height = this.dimensionsLecteur.height / 2;
            //} else {
                width = this.dimensionsLecteur.width / 2;
                height = Math.round(this.dimensionsLecteur.width * this.format.largeur / this.format.hauteur); //this.dimensionsLecteur.height;
            //}
            return {
                width: width,
                height: height
            }
        }

    },
    watch: {
        valide () {
            this.$emit('input', this.valide);
        },
        disponible() {
            if (this.disponible) {
                this.chargeFichier();
            }
        }
    },
    methods: {
        chargeFichier () {
            let url = (this.url !== '') ? this.url :  this.urlBAT + '/' + this.article.id
            return this.chargementPDF(url);
        },
        feuilleSuivante() {
            if (this.feuille < this.totalFeuilles + 1) this.feuille++;
        },
        feuillePrecedente() {
            if (this.feuille > 1) this.feuille--;
        },
        getDimensionsLecteur() {
            let a = this.$refs.bat;
            this.dimensionsLecteur = {
                width: a.clientWidth,
                height: a.clientHeight
            };
        },
    },
    mounted() {
        this.getDimensionsLecteur();
        if (this.disponible) {
            this.chargeFichier();
        }
    }
}
</script>

<style scoped lang="scss">
$largeur: min(100vw, 800px);
$lm20: $largeur - 20px;
.panel-bat {
    //height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 5px;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    width: $largeur;
    .controle-bat {

    }
    .header-bat {
        position: relative;
        width: $lm20;
        min-height: 60px;       //hauteur fixée pour que le BAT ne bouge pas quand les éléments contenus n'apparaissent pas
        & .infos-bat {
            text-align: center;
        }
        & .controle-bat {       //positionné en fixe pour eviter qu'ils remontent quand l'info n'est pas affichée
            width:100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            flex-direction:column;
            & > .controles {
                display:flex;
                flex-direction: row;
                justify-content:space-between;
            }
        }

    }
    .bat {
        width: $lm20;
        padding: 0;
        .page {
            display: flex;
            flex-direction: column;
            align-items: center;
            .contenu-page {
                width: fit-content;
                height: fit-content;
                //box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);
                //filter: drop-shadow(4px 4px 8px rgba(0, 0 ,0 , 0.35));
            }
        }
        &.portrait {
            display: flex;
            justify-content: center;
            &.livret {
                flex-direction: row-reverse;
                .page {
                    max-width: 49%;
                }
            }
            &.carte {
                flex-direction: row;
                .page {
                    max-width: 49%;
                }
            }
        }
        &.paysage {
            display: flex;
            &.livret {
                flex-direction: column-reverse;
            }
            &.carte {
                flex-direction: column;
            }
        }
        & .phrase-attente {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: center;
        }
        /* en livret, les deux pages sont collées l'une à l'autre, en carte, elles sont espacées de 20px */

    }

    .livret {
        &.portrait {
            .spacer-page {
                width: 1px;
            }
        }
        &.paysage {
            .spacer-page {
                height: 1px;
            }
        }

    }
    .carte {
        &.portrait {
            .spacer-page {
                width: 10px;
            }
        }
        &.paysage {
            .spacer-page {
                height: 0;

            }
        }
    }
    .mention {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: center;
        font-weight: 500;
        .important{
            line-height: 64px;
            color: red;
        }
    }
}

</style>