<template>
    <div class="contenu-page" ref="contenu-page">
        <canvas class="canvas-pdf"></canvas>
    </div>
</template>
<script>
import ConversionUniteMixin from '../../mixins/conversion/unite';
export default {
    name: "RenduPage",
    mixins: [
        ConversionUniteMixin
    ],
    data: () => ({
        container: null,
        canvas: null,
        context: null,
        pageEnCoursDeRendu: false,
        pageEnAttente: null,
        echelle: 1,             //pixel/mm => taille d'un millimètre en pixel
        dimensionsPage: {
            largeur: 1,
            hauteur: 1
        },
        trimbox: {
            x:0,
            y:0,
            largeur: 0,
            hauteur: 0
        },
        dimensionsCanvas: {
            largeur: 1,
            hauteur: 1
        },
        renduPDFPret: false,
        tacheRendu: null,
    }),
    props: {
        pdf: {
            type: Object,
            required: true
        },
        pdfDoc: {
            type: Object,
            required: true,
        },
        page: {
            type: Number,
            required: true
        },
        angle: {
            type: Number,
            default: 0,
        },
        zoom: {
            type: Number,
            default: -1,
        },
        trim: {
            type: Boolean,
            default: true
        },
        largeur: {
            type: Number,
            default: 0,
        },
        hauteur: {
            type: Number,
            default: 0
        }
    },
    computed: {
        totalPages() {
            return this.pdf ? this.pdf.numPages : 0;
        },
    },
    watch: {
        loaded(val) {
              if(val) this.fileRenduPage(this.page);
        },
        page (val, oldval) {
            if (val !== oldval) this.fileRenduPage(val);
        },
        zoom (val, oldval) {
            if (val !== oldval) this.fileRenduPage(this.page);
        },
        largeur (val, oldval) {
            if (this.zoom < 0 && val !== oldval) this.fileRenduPage(this.page);
        },
        hauteur (val, oldval) {
            if (this.zoom < 0 && val !== oldval) this.fileRenduPage(this.page);
        },
        renduPDFPret () {
            this.retourneValeurs();
        },
        trimbox: {
            handler () {
                this.retourneValeurs();
            },
            deep: true,
            immediate: true
        },
        dimensionsPage: {
            handler () {
                this.retourneValeurs();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        arrondi(valeur) {
            return Math.round(valeur * 100) / 100;
        },
        retourneValeurs () {
            this.$emit('input', {
                trimbox:      this.trimbox,
                dimension:    this.dimensionsPage,
                ready:        this.renduPDFPret
            });
        },
        fileRenduPage(num) { //un petit buffer pour eviter de déclencher un rendu pendant qu'un autre est déjà en cours
            this.renduPDFPret = false;
            if (this.tacheRendu !== null) {
                this.pageEnAttente = num;
               // if (typeof this.tacheRendu === 'object') this.tacheRendu.cancel(); //abrège le rendu en cours, inutile.
            } else {
                this.renduPage(num);
            }
        },
        renduPage(num) {
            if (this.pdf === null ||this.canvas === null || num > this.totalPages || num < 1) return;
            this.tacheRendu = true; // juste pour eviter qu'il ne soit
            // récupère la page choisie
            this.pdf.getPage(num).then(page => {
                this.pageEnCoursDeRendu = num;

                // Prepare le canvas pour utiliser les dimensionsPage de la page.
                let context = this.canvas.getContext('2d'),
                    echelle,
                    pageActuelle = this.pdfDoc.getPage(num - 1),
                    taille = pageActuelle.getSize(),
                    trimbox = pageActuelle.getTrimBox(),
                    largeurPicas,
                    hauteurPicas,
                    viewport,
                    //Pour eviter tout décalage, le rognage s'applique SEULEMENT si la taille massicoté est différente de la taille totale
                    trim = this.trim && (trimbox.width !== taille.width || trimbox.height !== taille.height);

                //défini si la largeur est celle de la trimbox ou de la mediabox
                if (trim) {
                    largeurPicas = trimbox.width;
                    hauteurPicas = trimbox.height;
                } else {
                    largeurPicas = taille.width;
                    hauteurPicas = taille.height;
                }

                this.trimbox = {
                    x:          this.arrondi(this.pica2mm(trimbox.x)),
                    y:          this.arrondi(this.pica2mm(trimbox.y)),
                    largeur:    this.arrondi(this.pica2mm(trimbox.width)),
                    hauteur:    this.arrondi(this.pica2mm(trimbox.height))
                };

                //voir https://www.usingenglish.com/static/lib/pdf.js/api/draft/util.js.html

                this.dimensionsPage.largeur = this.arrondi(this.pica2mm(largeurPicas));
                this.dimensionsPage.hauteur = this.arrondi(this.pica2mm(hauteurPicas));
                if (this.zoom <= 0 && this.largeur > 0 && this.hauteur > 0) {
                    let ratioCadre = this.largeur / this.hauteur,
                        ratioImage = largeurPicas / hauteurPicas;

                    echelle = (ratioCadre > ratioImage)
                        ? this.hauteur / hauteurPicas //l'image prend la hauteur du cadre
                        : this.largeur / largeurPicas;//l'image prend la largeur du cadre
                } else {
                    echelle = this.zoom;
                }

                if (trim) {
                    viewport = page.getViewport({
                        viewBox: [trimbox.x, trimbox.y, trimbox.width, trimbox.height],
                        scale: echelle,
                        offsetX: -trimbox.x,
                        offsetY: -trimbox.y,
                        dontFlip: false
                    });
                } else {
                    viewport = page.getViewport({
                        scale: echelle,
                        dontFlip: false
                    });
                }

                this.dimensionsCanvas.largeur = Math.round(largeurPicas * echelle); // Math.round(viewport.width);
                this.dimensionsCanvas.hauteur = Math.round(hauteurPicas * echelle); // Math.round(viewport.height);

                //proportion entre le viewport et la largeur de la page => donne l'échelle d'un 1mm à l'écran
                this.echelle = this.dimensionsCanvas.largeur / this.dimensionsPage.largeur;
                this.$emit('echelle', this.echelle);
                this.canvas.width = viewport.width;
                this.canvas.height = viewport.height;

                // fait le rendu de la page dans le context du canvas
                let renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                    intent: "display",
                    //enableWebGL: true,
                    background: 'rgba(255,255,255,0)'
                };
                this.context = renderContext;
                this.tacheRendu = page.render(renderContext);

                this.tacheRendu.promise.finally(() => {
                    if (this.pageEnAttente !== null) {
                        this.$emit('next', this.pageEnAttente);
                        // New page rendering is pending
                        this.renduPage(this.pageEnAttente);
                        this.pageEnAttente = null;
                    } else {
                        this.pageEnCoursDeRendu = false;
                        this.renduPDFPret = true;
                        this.tacheRendu = null;
                    }
                });
            });
        }
    },
    mounted() {
        //défini le canvas de destination
        this.container = this.$refs.contenuPage;
        this.canvas = this.$el.querySelector('canvas.canvas-pdf');
        this.fileRenduPage(this.page);
    }
}
</script>

<style scoped lang="scss">
.contenu-page {
   width: fit-content !important;
   height: fit-content !important;
    .canvas-pdf {
        border: 1px solid grey;
        display: flex;
        flex-direction: column;
        background-color: white;
//        max-width: 100%;
    }
}
</style>