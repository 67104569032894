<template>
    <div class="regle" :class="orientation">
        <canvas class="graduation"></canvas>
    </div>
</template>

<script>
const LONGUEUR_REGLE = 600; //en millimetre
export default {
    name: "Regle",
    data: () => ({
        canvas: null,
        context: null,
        taille: 25,
    }),
    props: {
        orientation: {
            type: String,
            default: "horizontale",
        },
        echelle: {          // l'échelle d'affichage globale (nombre de pixel / mm)
            type: Number,
            required: true
        },
        offset: {
            type: Number,
            default: 0
        }
    },
    computed: {
    },
    watch: {
        echelle: {
            handler () {
                if (this.canvas) this.dessine();
            },
            immediate: true
        }
    },
    methods: {
        dessine() {
            this.canvas.font = "9px monospace";
            if (this.orientation === 'horizontale') {
                this.graduationHorizontale();
            } else {
                this.graduationVerticale();
            }
        },
        graduationHorizontale(distance) {
            this.canvas.width = this.echelle * LONGUEUR_REGLE;
            this.canvas.height = this.taille;
            this.context.fillStyle = 'black';
            this.context.fillRect(0,0, this.canvas.width, this.canvas.height);
            this.context.fillStyle = 'white';
            this.context.strokeStyle = 'white';
            this.context.beginPath();
            for(let i = 0; i < LONGUEUR_REGLE; i++) {
                let x = i * this.echelle + this.offset,
                    y = this.taille - 4;
                if (i % 10 === 0) {
                    y = this.taille - 8;
                    if(i > 0 || this.offset > 0) {
                        let texte = i.toString(),
                            metrique = this.context.measureText(texte),
                            largeur = metrique['width'];
                        this.context.fillText(texte, x - (largeur / 2), 12,);
                    }
                } else if (i % 5 === 0) {
                    y = this.taille - 6;
                }

                this.context.moveTo(x, y);
                this.context.lineTo(x,this.taille);

            }
            if (distance) {
                this.context.moveTo(distance, 0);
                this.context.lineTo(distance, this.taille);
            }
            this.context.stroke();
        },
        graduationVerticale(distance) {
            this.canvas.width = this.taille;
            this.canvas.height = this.echelle * LONGUEUR_REGLE;
            this.context.fillStyle = 'black';
            this.context.fillRect(0,0, this.canvas.width, this.canvas.height);
            this.context.fillStyle = 'white';
            this.context.strokeStyle = 'white';
            this.context.beginPath();
            for(let i = 0; i < LONGUEUR_REGLE; i++) {
                let y = i * this.echelle + this.offset,
                    x = this.taille - 4;

                if (i % 10 === 0) {
                    x = this.taille - 8;
                    if(i > 0 || this.offset > 0) {
                        let texte = i.toString(),
                            metrique = this.context.measureText(texte),
                            largeur = Math.min(metrique['width'], x);
                        this.context.fillText(texte, 8 - (largeur / 2), y + 3.5 , 17);
                    }
                } else if (i % 5 === 0) {
                    x = this.taille - 6;
                }

                this.context.moveTo( x, y);
                this.context.lineTo( this.taille,y);

            }
            if (distance) {
                this.context.moveTo( 0, distance);
                this.context.lineTo( this.taille,distance);
            }
            this.context.stroke();
        },
        coordonnees (event) {
            let distance = 0;
            if (this.orientation === 'horizontale') {
                distance = event.clientX - this.canvas.getBoundingClientRect().x;
                this.graduationHorizontale(distance);
            } else {
                distance = event.clientY - this.canvas.getBoundingClientRect().y;
                this.graduationVerticale(distance);
            }
            this.$emit('input',  distance / this.echelle);
        }
    },
    mounted() {
        this.canvas = this.$el.querySelector('canvas.graduation');
        this.context = this.canvas.getContext("2d");
        window.addEventListener('mousemove', this.coordonnees);

    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.coordonnees);
    }
}
</script>

<style scoped lang="scss">
$couleur_regle:#A0A0A0;
.regle {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $couleur_regle;
    //opacity: 0.75;
    border: 1px solid #555555;
}
.horizontale {
    left: 25px;
    height: 25px;
    max-width: calc(100% - 25px);
    overflow: hidden;
    >canvas {
        top : 0;
        left: 25px;
    }
}
.verticale {
    top: 25px;
    width: 25px;
    height: calc(100% - 25px);
    overflow: hidden;
    >canvas {
        top : 25px;
        left: 0;
    }
}
</style>