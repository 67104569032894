<template>
    <div class="produit-images">
        <v-img-portail class="produit-image-principale" :dist-url="imgSelect.url"></v-img-portail>
        <div v-if="medias.length > 0" class="produit-image-liste">
            <!--            <img-portail :dist-url="defaultImageUrl" :alt="titre" class="produit-image-principale"/>-->
            <v-img-portail
                v-if="this.defaultImageUrl"
                @click="selectAlt(-1)"
                :class="{ selected: select === -1 }"
                :dist-url="defaultImageUrl"
                :alt="titre"
                class="produit-image-vignettes">
            </v-img-portail>
            <v-img-portail
                v-for="(media, keyMed) in medias"
                :key="media.id"
                :class="{ selected: select === keyMed }"
                @click="selectAlt(keyMed)"
                :dist-url="urlVignette + media.fichier"
                :alt="media.titre"
                :contains="true"
                class="produit-image-vignettes">
            </v-img-portail>
        </div>
    </div>
</template>

<script>
import VImgPortail from "./VImgPortail";

export default {
    name: "ImgMedia",
    components: {
        VImgPortail
    },
    props: {
        defaultImageUrl: String,
        titre : String,
        medias : Array
    },
    data: () => ({
        urlVignette:    process.env.VUE_APP_API_URL + '/media/thumb/',
        urlVignetteHD:  process.env.VUE_APP_API_URL + '/image/vignette/grande',
        urlImage:       process.env.VUE_APP_API_URL + '/media/dl/',
        select: -1
    }),
    computed: {
        imgSelect() {
            if (this.select === -1)
                return {
                    url: this.defaultImageUrl ? this.defaultImageUrl : this.urlVignetteHD + '/' +  encodeURIComponent('nopicture.jpg'),
                    titre: this.titre
                };
            else
                return {
                    url: this.urlImage + this.medias[this.select].nom_original,
                    titre: this.medias[this.select].nom_original
                };
        }
    },
    methods: {
        selectAlt(ind) {
            if (ind === -1) this.$emit('click') //pour ouvrir le flipbook dans la fenetre Produit.
            this.select = ind;
        }
    },
    beforeMount() {
        if(!this.defaultImageUrl && this.medias.length)
            this.select = 0;
    }
}
</script>

<style lang="scss" scoped>

.produit-images {
    min-height: 400px;
    display:flex;
    flex-direction:column;
    .produit-image-principale {
        max-width:min(800px, 100vw);
        @media (min-width:840px) and (max-width:1279px){
            max-width:calc(100vw - 464px)
        }
        max-height:450px;
        border: 1px solid #707070;
        //box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    }
    .produit-image-liste {
        overflow-x:auto;
        margin-top:10px;
        display:flex;
        flex-direction: row;
        grid-gap:15px;
        .produit-image-vignettes {
            max-height:51px;
            max-width:90px;
        }
        .selected {
            outline: 1px solid #00D5FF;
            outline-offset: -1px;
        }
    }
}
</style>