<template>
    <v-dialog
        :width="dialogWidth"
        :value="value"
        scrollable
        @input="$emit('input')"
    >
        <v-card>
            <v-card-title>
                Galerie d'image
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <v-col :cols="3">
                        <template v-if="!personnellesSeulement">
                            <div
                                v-for="(tagCategorie, index) in listeCategories"
                                class="row"
                                :key="`categorie-${index}`"
                                @click="selectionneCategorie(index)"
                            >
                                <v-col class="pa-1 text-center font-weight-bold">
                                    <div class="categorie-container" :class="{ 'categorie-selectionnee': categorieSelectionneIndex === index }">
                                        {{ tagCategorie.libelle }}
                                    </div>
                                </v-col>
                            </div>
                        </template>
                        <div
                            class="row"
                            @click="selectionneCategorie(-1)"
                            v-if="!categoriesSeulement"
                        >
                            <v-col class="pa-1 text-center font-weight-bold">
                                <div class="categorie-container" :class="{ 'categorie-selectionnee': categorieSelectionnee === null }">
                                    Personnelles
                                </div>
                            </v-col>
                        </div>
                    </v-col>
                    <v-col :cols="9">
                        <div class="row">
                            <v-col
                                v-for="(image, index) in listeImagePaginee[page -1]"
                                :key="`vignette-image-${index}`"
                                :cols="3"
                                :md="2"
                                class="pa-1"
                            >
                                <v-card tile @click="selectionneImage(index)" :class="{ 'image-selectionnee': index === imageSelectionneeIndex }">
                                    <v-card-text class="pa-1">
                                        <v-img-portail contain :dist-url="vignette(image.fichier)" :min-height="100" :height="imageHeight" @size-changed="setImageHeight">
                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular indeterminate color="grey lighten-5"/>
                                                </v-row>
                                            </template>
                                        </v-img-portail>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col
                                v-if="categorieSelectionneeEstPersonnel && autoriseUpload"
                                :cols="3"
                                :md="2"
                                class="pa-1"
                            >
                                <v-card :min-height="100" tile class="fill-height" title="Ajouter une image" @click="selectionImagePourUpload">
                                    <v-card-text class="pa-1 fill-height d-flex justify-center align-center">
                                        <v-icon x-large>{{ mdiPlus }}</v-icon>
                                        <input ref="uploadInput" type="file" accept="image/jpeg,image/png,image/svg+xml" v-show="false" @change="uploadFichierSelectionne">
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </div>
                    </v-col>
                </div>
            </v-card-text>
            <v-card-actions class="flex-wrap">
                <v-col :cols="9" :offset="3" class="text-center">
                    <v-pagination
                        v-model="page"
                        :length="listeImagePaginee.length"
                    />
                </v-col>
                <v-col :cols="12">
                    <btn-theme tile @click="$emit('input', false)">
                        Annuler
                    </btn-theme>
                    <template v-if="!fermeSurSelection">
                        <v-spacer/>
                        <btn-theme tile :disabled="imageSelectionnee === null">
                            Sélectionner
                        </btn-theme>
                    </template>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import Api from '../api/api';
import VImgPortail from "./theme/VImgPortail";
import BtnTheme from "./theme/BtnTheme";
import {mapGetters} from "vuex";
import { mdiPlus } from "@mdi/js";

export default {
    name: 'GalerieImages',
    components: {
        BtnTheme,
        VImgPortail
    },
    data: () => ({
        getListeImageURL        : process.env.VUE_APP_API_URL + '/media/liste/image',
        getImagesPersonnels     : process.env.VUE_APP_API_URL + '/media/personnels/image',
        getThumbnailImageURL    : process.env.VUE_APP_API_URL + '/media/thumb',
        getListeCategoriesURL   : process.env.VUE_APP_API_URL + '/media/categories',
        postUploadMediaURL      : process.env.VUE_APP_API_URL + '/media/upload',

        listeCategories  : [],
        listeImages: [],

        imageHeight: undefined,
        categorieSelectionneIndex: -1,
        imageSelectionneeIndex: -1,
        page: 1,

        dialogWidth: Math.Infinity,
        mdiPlus
    }),
    props: {
        value: {
            type: Boolean,
            default: false
        },
        categoriesSeulement: {
            type: Boolean,
            default: false
        },
        personnellesSeulement: {
            type: Boolean,
            default: false
        },
        fermeSurSelection: {
            type: Boolean,
            default: false
        },
        autoriseUpload: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters([
            'isLogged'
        ]),
        imageSelectionnee () {
            if (this.imageSelectionneeIndex === -1) return null;

            return this.listeImages[this.imageSelectionneeIndex];
        },
        categorieSelectionnee () {
            if (this.categorieSelectionneIndex === -1) return null;

            return this.listeCategories[this.categorieSelectionneIndex];
        },
        categorieSelectionneeEstPersonnel () {
            return this.categorieSelectionnee === null;
        },
        imagesParLigne () {
            return (this.$vuetify.breakpoint.mdAndUp)
                ? 6
                : 4;
        },
        listeImagePaginee () {
            let listePaginee = [],
                elementsParPage = 4 * this.imagesParLigne;

            for (let i = 0; i < this.listeImages.length; i += elementsParPage) {
                listePaginee.push(this.listeImages.slice(i, i + elementsParPage));
            }

            return listePaginee;
        }
    },
    watch: {
        categorieSelectionnee: {
            handler () {
                this.getListeImagesCategorie();
                this.imageSelectionneeIndex = -1;
            },
            immediate: true
        },
        'listeImagePaginee.length' (length) {
            if (length < this.page) {
                this.page = length;
            }
        }
    },
    methods: {
        getListeCategories () {
            Api.get(this.getListeCategoriesURL).then(liste => {
               this.listeCategories = Object.assign([], liste);
               if (this.categorieSelectionneIndex === null) this.categorieSelectionneIndex = 0; //choisi la 1ere catégorie
            })
        },
        getListeImagesCategorie () {
            if (!this.isLogged) return;
            let promise = this.categorieSelectionnee === null ? Api.get(this.getImagesPersonnels)
                                                              : Api.get(this.getListeImageURL + '/' + this.categorieSelectionnee.slug);
            promise.then(liste => {
                this.listeImages = Object.assign([], liste);
            });
        },
        vignette (image) {
            return this.getThumbnailImageURL + '/' + image;
        },
        selectionneImage (index) {
            this.imageSelectionneeIndex = index + ((this.page -1) * this.imagesParLigne * 4);
            this.$nextTick(() => {
                this.$emit('selection', this.imageSelectionnee);
                if (this.fermeSurSelection) {
                    this.$emit('input', false);
                    this.imageSelectionneeIndex = -1;
                }
            });
        },
        selectionneCategorie (index) {
            this.categorieSelectionneIndex = index;
        },
        setImageHeight (e) {
            // noinspection JSSuspiciousNameCombination
            this.imageHeight = e.offsetWidth;
        },
        selectionImagePourUpload () {
            if (!this.isLogged) this.$router.push('/connexion');
            else                this.$refs.uploadInput.click();
        },
        uploadFichierSelectionne () {
            if (this.$refs.uploadInput.files.length < 1) return;

            let file = this.$refs.uploadInput.files[0],
                data = new FormData();

            data.append('file', file);
            data.append('reference', '');
            data.append('personnelle', '1');

            Api.post(this.postUploadMediaURL, data)
                .then(media => {
                    this.getListeImagesCategorie();
                    if (this.fermeSurSelection) {
                        this.$emit('selection', media);
                        this.$emit('input', false);
                    }
                })
        }
    },
    mounted () {
        if (!this.personnellesSeulement) {
            this.getListeCategories();
        } else {
            this.getListeImagesCategorie()
        }
    }
}

</script>
<style lang="scss" scoped>

.image-selectionnee {
    border: 3px solid #000000;
    border-radius: 2px !important;
}
.categorie-selectionnee {
    background-color: #000;
    color: #fff;
    border-radius: 4px;
}
.categorie-container {
    cursor: pointer;
}

</style>