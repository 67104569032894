/*
 * partie commune à tout les élements de type texte (texte, bloc, adresse)
 * - Ces composants ne modifient pas l'élement passé en props donc, pas besoin d'avoir une version modifiée
 */
import ConversionCouleur from "../../../../mixins/conversion/couleur";
import ElementMixin from "./element";
const K_CELL_HEIGHT_RATIO = 1.25; //ratio utilisé dans TCPDF
export default {
    mixins: [
        ElementMixin,
        ConversionCouleur,
    ],
    data: () => ({
        urlChargeFont: process.env.VUE_APP_API_URL +  '/media/reference',
        canvas: null,
        context: null,
        padding: {  //exprimé en pixels, des cotés suit celui de TCPDF
            T: 0,
            R: 0,
            B: 0,
            L: 0,
        },
        fontAscent:  0,//ces 2 variables sont définies lors de l'initialisation du canvas
        fontDescent: 0,
        ratioHauteurCellule: K_CELL_HEIGHT_RATIO,
        nombresLignes: 1,
        nomFont: '',
        espaceVertical: 0,
        largeurParDefaut: 500
    }),
    props: {
        listeFonts: {
            type: Object,
            required: true
        }
    },
    computed: {
        cadre () { //exprimé en mm, uniquement définie pour rendre plus lisible les variables
            let largeur = this.value.position.l;
            if (largeur === undefined) largeur = 0;
            let hauteur = this.value.position.h;
            if (hauteur === undefined) hauteur = 0;
            let dimensions = {
                x:        this.value.position.x,
                y:        this.value.position.y,
                largeur:  largeur ? largeur : this.largeurParDefaut,
                hauteur:  Math.max(hauteur, this.hauteurMinimale),
            };
            switch (this.value.position.calign) {
                case 'M':   // la position y de la cellule est par rapport à son millieu
                    dimensions.y -= (dimensions.hauteur / 2);
                    break;
                case 'B':   // la position y de la cellule est par rapport à son bas
                    dimensions.y -= dimensions.hauteur;
                    break;
                //non pris en charge => les positions relatives au baseline de la font.
                default:
            }
            return dimensions;
        },
        cadreEnPouce () { //exprimé en pouce, pour determiner si la densité de l'image est suffisante
            return {
                largeur:  this.mm2inch(this.value.position.l),
                hauteur:  this.mm2inch(this.value.position.h),
            };
        },
        cadreEnPixel () { //exprimé en pixel, uniquement définie pour rendre plus lisible les variables
            return {
                x:        Math.round(this.cadre.x * this.echelle),
                y:        Math.round(this.cadre.y * this.echelle),
                largeur:  Math.ceil(this.cadre.largeur * this.echelle),
                hauteur:  Math.ceil(this.cadre.hauteur * this.echelle),
            };
        },
        styleCalculeCadre () {
            let style = {
                left:   this.cadreEnPixel.x.toString() + 'px',
                top:    this.cadreEnPixel.y.toString() + 'px',
                width:  this.cadreEnPixel.largeur.toString() + 'px',
                height: this.cadreEnPixel.hauteur.toString() + 'px'
            }
            //gestion de la rotation du bloc texte => TCPDF gère la rotation par le coin supérieur gauche, en sens trigonométrique (inverse du CSS)
            if (this.value.position.angle) {
                style["transform-Origin"] =  "top left";
                style.transform = 'rotate(-' + this.value.position.angle + 'deg)';
            }
            return style;
        },
        fontSizeEnPixel () { //exprimée en pixel
            return Math.round(this.value.font.size * this.echellePicas);
        },
        fontItalic () {
            if (!Object.hasOwnProperty.call(this.value.font, 'style')) return '';
            let style = this.value.font.style;
            return style.includes("I") ? "italic " : "";
        },
        fontBold () {
            if (!Object.hasOwnProperty.call(this.value.font, 'style')) return '';
            let style = this.value.font.style;
            return style.includes("B") ? "bold " : "";
        },
        fontColor () { //converti la couleur cmjn et la valeur alpha en rgba
            let alpha = this.value.alpha;
            if (alpha === undefined) alpha = 1;
            if (alpha > 1) alpha = alpha / 100;
            return this.toRGBA(...this.CMYKtoRGB(...this.value.color), alpha);
        },
        fontFormat () {
            return this.fontItalic + this.fontBold + this.fontSizeEnPixel.toString() + "px " + this.nomFont;
        },
        erreurFont () {
            return !Object.hasOwnProperty.call(this.listeFonts, this.value.font.name);
        }
    },
    methods: {
        hauteurCellule (tailleFont) {
            return  Math.round(1000000 * ((tailleFont * this.ratioHauteurCellule) + this.padding['T'] + this.padding['B'])) / 1000000;
        },
        setPadding() {
            //note: le padding dans TCPDF est noté en point picas
            let padding = this.value.padding;
            //si aucun padding n'est défini, on le défini à 0
            if (padding === undefined) padding = 0;
            //si le padding est fourni sous la forme d'une seule valeur, on en fait un tableau de 4 valeurs identiques
            if (typeof padding !== "object") padding = [padding, padding, padding, padding];
            this.padding['T'] = padding[0] * this.echelle;    //le padding sur le document est exprimé en millimetre
            this.padding['R'] = padding[1] * this.echelle;
            this.padding['B'] = padding[2] * this.echelle;
            this.padding['L'] = padding[3] * this.echelle;
        },
        initCanvas() {
            this.setPadding();
            this.hauteurMinimale = this.hauteurCellule(this.nombresLignes * this.fontSizeEnPixel) / this.echelle;
            this.canvas.width =  this.cadreEnPixel.largeur;
            this.canvas.height = this.cadreEnPixel.hauteur;
            this.context.clearRect(0,0, this.context.width, this.context.height);
            this.context.font = this.fontFormat;
            let metriques =  this.context.measureText(this.value.data);
            //Note de DF: sur des fonts mal foutues, il semble que le font ascent renvoyé est supérieur à la taille de la font !
            this.fontAscent = Math.min(metriques['actualBoundingBoxAscent'], this.fontSizeEnPixel);
            this.fontDescent = metriques['actualBoundingBoxDescent'];
        },
        largeurTexteEnPixel(texte) {
            this.context.font = this.fontFormat;
            return this.context.measureText(texte)['width'];
        },
        gereAlignement(largeurTexte, hauteurTexte) {
            /*
             * Cette fonction gère la position du debut du texte en fonction de
             * l'alignement horizontal et vertical
             */
            //calcul de y suivant la valeur de valign
            let position = {
                X: 0,
                Y: 0
            };

            position.Y = this.fontAscent; //Y est positionné sur le baseline de la 1ère ligne de texte
            this.espaceVertical = this.cadreEnPixel.hauteur - hauteurTexte + this.padding['T'] + this.padding['B'];

            switch(this.value.position.valign) {
                case 'T':  //y est égale à la baseline
                    //la partie '...Ascent' correspond à la partie de la font située au dessus de la baseline
                    //la partie '...Descent' correspond à la partie en dessous de la baseline
                    position.Y += this.padding['T'];
                    break;
                case 'B':
                    position.Y += this.cadreEnPixel.hauteur - (this.padding['B'] + hauteurTexte);
                    break;
                default: //par défaut l'alignement vertical est centré (M)
                    position.Y += this.padding['T'] + ((this.cadreEnPixel.hauteur - (hauteurTexte + this.padding['T'] + this.padding['B'])) / 2);
            }
            switch(this.value.position.align) {
                case 'C':  //y est égale à la baseline
                    position.X = this.padding['L']+ ((this.cadreEnPixel.largeur - (this.padding['L'] + this.padding['R'] + largeurTexte)) / 2);
                    break;
                case 'R':
                    position.X = this.cadreEnPixel.largeur - (largeurTexte + this.padding['R']);
                    break;
                default: //par défaut, c'est l'alignement horizontal est à gauche (L)
                    position.X = this.padding['L'];
            }

            return position;
        }
    },
    mounted() {
        this.canvas = this.$el.querySelector('canvas.dessin');
        this.context = this.canvas.getContext("2d");
        this.nomFont = this.listeFonts[this.value.font.name];
    }
}