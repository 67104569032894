export default class Code128 {
    constructor (code, type= '') {
        this.code = code;
        this.BARS = [
            '212222', /* 00 */
            '222122', /* 01 */
            '222221', /* 02 */
            '121223', /* 03 */
            '121322', /* 04 */
            '131222', /* 05 */
            '122213', /* 06 */
            '122312', /* 07 */
            '132212', /* 08 */
            '221213', /* 09 */
            '221312', /* 10 */
            '231212', /* 11 */
            '112232', /* 12 */
            '122132', /* 13 */
            '122231', /* 14 */
            '113222', /* 15 */
            '123122', /* 16 */
            '123221', /* 17 */
            '223211', /* 18 */
            '221132', /* 19 */
            '221231', /* 20 */
            '213212', /* 21 */
            '223112', /* 22 */
            '312131', /* 23 */
            '311222', /* 24 */
            '321122', /* 25 */
            '321221', /* 26 */
            '312212', /* 27 */
            '322112', /* 28 */
            '322211', /* 29 */
            '212123', /* 30 */
            '212321', /* 31 */
            '232121', /* 32 */
            '111323', /* 33 */
            '131123', /* 34 */
            '131321', /* 35 */
            '112313', /* 36 */
            '132113', /* 37 */
            '132311', /* 38 */
            '211313', /* 39 */
            '231113', /* 40 */
            '231311', /* 41 */
            '112133', /* 42 */
            '112331', /* 43 */
            '132131', /* 44 */
            '113123', /* 45 */
            '113321', /* 46 */
            '133121', /* 47 */
            '313121', /* 48 */
            '211331', /* 49 */
            '231131', /* 50 */
            '213113', /* 51 */
            '213311', /* 52 */
            '213131', /* 53 */
            '311123', /* 54 */
            '311321', /* 55 */
            '331121', /* 56 */
            '312113', /* 57 */
            '312311', /* 58 */
            '332111', /* 59 */
            '314111', /* 60 */
            '221411', /* 61 */
            '431111', /* 62 */
            '111224', /* 63 */
            '111422', /* 64 */
            '121124', /* 65 */
            '121421', /* 66 */
            '141122', /* 67 */
            '141221', /* 68 */
            '112214', /* 69 */
            '112412', /* 70 */
            '122114', /* 71 */
            '122411', /* 72 */
            '142112', /* 73 */
            '142211', /* 74 */
            '241211', /* 75 */
            '221114', /* 76 */
            '413111', /* 77 */
            '241112', /* 78 */
            '134111', /* 79 */
            '111242', /* 80 */
            '121142', /* 81 */
            '121241', /* 82 */
            '114212', /* 83 */
            '124112', /* 84 */
            '124211', /* 85 */
            '411212', /* 86 */
            '421112', /* 87 */
            '421211', /* 88 */
            '212141', /* 89 */
            '214121', /* 90 */
            '412121', /* 91 */
            '111143', /* 92 */
            '111341', /* 93 */
            '131141', /* 94 */
            '114113', /* 95 */
            '114311', /* 96 */
            '411113', /* 97 */
            '411311', /* 98 */
            '113141', /* 99 */
            '114131', /* 100 */
            '311141', /* 101 */
            '411131', /* 102 */
            '211412', /* 103 START A */
            '211214', /* 104 START B */
            '211232', /* 105 START C */
            '233111', /* STOP */
            '200000'  /* END */
        ]
        this.STOP = 106;
        this.END = 107;
        this.bancodeIndex = [];
        this.resData = '';
        this.type = type;
    }
    bs (bandCode) {

        for(let y in bandCode) {
            for(let t = 0; t < parseInt(bandCode[y]); t++) {
                //alterne la couleur de la barre entre noir (B) et blanc (s)
                this.resData += (y % 2 === 0) ? 'b' : 's';

//                y % 2 == 0 && (this.resData += 'b', true) || (this.resData += 's')

            }

        }

    }

    checkCode () {
        let sum = this.type.charCodeAt() + 38; // A = 103, B = 104, C = 105
        for (let i=0; i < this.bancodeIndex.length; i++) {
           sum += (parseInt(i) + 1) * this.bancodeIndex[i];
        }
        return this.BARS[sum % 103]; //la séquence est entre 0 et 102 (les séquences d'après correspondent aux séquences de départ et de fin)
    }

    //prépare la séquence code de début, code de la valeur, code de stop et code de fin
    calcSequences() {
        this.resData = '';
        let offset = 32; //Déviation de la table de code ASCII, 32 si classe A.B, 48 si classe C chiffres purs
        //Déterminer le code ABC approprié pour le code à barres d'entrée.
        if (this.type === 'C' || (/^\d*$/.test(this.code) && !(this.code.length % 2))) {
            //S'il s'agit d'un nombre pair de chiffres et d'un numéro stocké, c'est un type C.
            this.type = 'C'
            offset = 48;
        } else if (this.type === 'B' || (/[a-z]+/.test(this.code))) { //Si le code comporte des lettres, c'est le type B
            this.type = 'B'
        } else { //Si il ne contient que des chiffres, c'est le type A
            this.type = 'A'
        }
        //défini l'alternance des barres blanches et des barres noires
        this.resData = '';

        let startIndex = this.type.charCodeAt() + 38; //nombre entre 103 (65+38) et 105 (67+38)
        //barres de début
        this.bs(this.BARS[startIndex] + '');

        //barres des données
        for (let i=0; i < this.code.length; i++) {
            let index = this.code[i].charCodeAt() - offset;
            let bandCode = this.BARS[index] + '';
            this.bancodeIndex.push(index);
            this.bs(bandCode);
        }

        //barres de somme de contrôle
        this.bs(this.checkCode() + '');

        //barres de fin
        this.bs(this.BARS[this.STOP] + '');
        this.bs(this.BARS[this.END] + '');
        // à ce point, this.resData contient la définition des barres
        return this.resData;
    }
    draw (canvas, width, height) {
        let ctx = canvas.getContext("2d");
        canvas.width =  width;
        canvas.height = height;

        this.calcSequences();
//        ctx.createImageData(width, height);
        let bar_width = Math.round(width / this.resData.length)
        let num = 0;
        for (let i = 0; i < this.resData.length; i++) {
            ctx.fillStyle = this.resData[i] === 's' ? 'white' : 'black';
            ctx.fillRect(num * bar_width, 0, bar_width, height);
            num++;
        }

    }
}