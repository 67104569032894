<template>
  <div class="perso-datamatrix" :style="styleCalculeCadre">
    <canvas class="dessin"></canvas>
  </div>
</template>

<script>
import CodeBarreMixin from "./mixins/codebarre";
export default {
  name: "ElementDatamatrix",
  mixins: [
    CodeBarreMixin
  ],
  computed: {
    styleCalculeCadre () {
      let style = {
        left:   this.cadreEnPixel.x.toString() + 'px',
        top:    this.cadreEnPixel.y.toString() + 'px',
        width:  this.cadreEnPixel.largeur.toString() + 'px',
        height: this.cadreEnPixel.hauteur.toString() + 'px'
      }
      //gestion de la rotation du bloc texte => TCPDF gère la rotation par le coin supérieur gauche, en sens trigonométrique (inverse du CSS)
      if (this.elementModifie.position.angle) {
        style["transform-Origin"] =  "top left";
        style.transform = 'rotate(-' + this.value.position.angle + 'deg)';
      }
      return style;
    },
  },
  watch: {
    echelle (val, old) {
      if (val !== old) this.rendu();
    },
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.perso-datamatrix {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>