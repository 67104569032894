import {mapGetters} from "vuex";
export default {
    data: () => ({
        dataUrl: '',
        sizeObserver: null
    }),
    props: {
        distUrl: {
            type: String,
            default:null
        }
    },
    watch: {
        distUrl: {
            handler (val) {
                this.dataUrl = this.addBearer(val)
            },
            immediate: true
        }
    },
    computed: {
        ...mapGetters([
            'bearer'
        ]),
    },
    methods: {
        createObserver() {
            this.sizeObserver = new ResizeObserver(() => {
                this.$emit('size-changed', this.$el);
            });
            this.sizeObserver.observe(this.$el);
        },
        destroyObserver() {
            this.sizeObserver.disconnect();
            this.sizeObserver = null;
        },
        addBearer(url) {
            if (!url) return null;
            if (url.includes('Bearer=')) return url; //on n'ajoute pas l'authentification si elle y est déjà
            let caractere_jonction = '?';
            if (url.includes(caractere_jonction)) caractere_jonction = '&';
            return url + caractere_jonction + 'Bearer=' + this.bearer;
        }
    },
    mounted () {
        this.createObserver();
    },
    beforeDestroy () {
        this.destroyObserver();
    }
}
