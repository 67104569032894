import ConversionUniteMixin from '../../../mixins/conversion/unite';
export default {
    mixins: [
        ConversionUniteMixin
    ],
    data: () => ({
        page: 1,
        canvas: null,
        canvasZones: null,
        pageEnCoursDeRendu: false,
        pageEnAttente: null,
        echelle: 1,             //pixel/mm => taille d'un millimètre en pixel
        dimensionsPage: {
            largeur: 1,
            hauteur: 1
        },
        trimbox: {
            x:0,
            y:0,
            largeur: 0,
            hauteur: 0
        },
        mediabox: {
            x: 0,
            y: 0,
            largeur: 0,
            hauteur: 0
        },
        cropbox: {
            x: 0,
            y: 0,
            largeur: 0,
            hauteur: 0
        },
        dimensionsCanvas: {
            largeur: 1,
            hauteur: 1
        },
        renduPDFPret: false,
        largeur: 1000,
        hauteur: 1000,
        zoom: 1,
    }),
    computed: {
        totalPages() {
            return this.pdf ? this.pdf.numPages : 0;
        },
    },
    watch: {
        loaded(val)  {
            if (val) this.fileRenduPage(this.page);
        },
        page (val, oldval) {
            if (val !== oldval && val >= 1 && val <= this.totalPages) this.fileRenduPage(val);
        },
        zoom (val, oldval) {
            if (val !== oldval) this.fileRenduPage(this.page);
        },
        largeur (val, oldval) {
            if (this.zoom < 0 && val !== oldval) this.fileRenduPage(this.page);
        },
        hauteur (val, oldval) {
            if (this.zoom < 0 && val !== oldval) this.fileRenduPage(this.page);
        },
    },
    methods: {
        fileRenduPage(num) { //un petit buffer pour eviter de déclencher un rendu pendant qu'un autre est déjà en cours
            this.renduPDFPret = false;
            if (this.pageEnCoursDeRendu === false) {
                this.renduPage(num);
            } else {
                if (num !== this.pageEnCoursDeRendu) this.pageEnAttente = num;
            }
        },
        renduPage(num) {
            if (this.pdf === null || num > this.totalPages || this.canvas === null) return;
            // récupère la page choisie
            this.pageEnCoursDeRendu = num;
            this.pdf.getPage(num).then(page => {


                // Prepare le canvas pour utiliser les dimensionsPage de la page.
                let context = this.canvas.getContext('2d'),
                    echelle,
                    largeurPicas,
                    hauteurPicas;

                if (this.pdfDoc) { //si la librarie de gestion du pdf est chargé, on va chercher les métas données nécéssaires
                    let pageActuelle = this.pdfDoc.getPage(num - 1),
                        taille = pageActuelle.getSize(),
                        trimbox = pageActuelle.getTrimBox(),
                        mediabox = pageActuelle.getMediaBox(),
                        cropbox = pageActuelle.getCropBox(),
                        rotation = pageActuelle.getRotation().angle;

                    //si le pdf contient une rotation, toutes les dimensions reçues sont à intervertir
                    if (rotation === 90 || rotation === 270) {
                        [taille.width, taille.height] = [taille.height, taille.width];
                        [trimbox.width, trimbox.height] = [trimbox.height, trimbox.width];
                        [mediabox.width, taille.height] = [mediabox.height, mediabox.width];
                        [cropbox.width, taille.height] = [cropbox.height, cropbox.width];
                    }
                    largeurPicas = taille.width;
                    hauteurPicas = taille.height;

                    this.trimbox  = this.transposePdfZone(trimbox, taille);
                    this.mediabox = this.transposePdfZone(mediabox, taille);
                    this.cropbox  = this.transposePdfZone(cropbox, taille);

                    // Avertissement si une zone est en dehors de la mediabox
                    let zoneEnDehors = this.zoneEnDehorsDeMediaBox(this.trimbox, this.mediabox)
                                    || this.zoneEnDehorsDeMediaBox(this.cropbox, this.mediabox);
                    if (zoneEnDehors) {
                        this.$store.commit('addSnackMessage', {msg: 'Il semble que le PDF contient des zones incohérentes. Le rendu ainsi que la prévisualisation peuvent être différents du résultat attendu.'});
                    }
                } else { //sinon on les determine empiriquement par comparaison avec l'échelle 1 qui renvoi la dimensions
                    let viewportMesure;
                    viewportMesure = page.getViewport({scale: 1});
                    largeurPicas = viewportMesure.width;
                    hauteurPicas = viewportMesure.height;
                    this.trimbox = {
                        x: 0,
                        y: 0,
                        largeur: Math.round(this.pica2mm(largeurPicas) * 100) / 100 ,
                        hauteur: Math.round(this.pica2mm(hauteurPicas) * 100) / 100
                    };
                }


                this.dimensionsPage.largeur = Math.round(this.pica2mm(largeurPicas) * 100) / 100;
                this.dimensionsPage.hauteur = Math.round(this.pica2mm(hauteurPicas) * 100) / 100;
                if (this.zoom <= 0 && this.largeur > 0 && this.hauteur > 0) {
                    let ratioCadre = this.largeur / this.hauteur,
                        ratioImage = largeurPicas / hauteurPicas;

                    echelle = (ratioCadre > ratioImage)
                                ? this.hauteur / hauteurPicas //l'image prend la hauteur du cadre
                                : this.largeur / largeurPicas;//l'image prend la largeur du cadre
                } else {
                    echelle = this.zoom;
                }
                let viewport = page.getViewport({scale: echelle});
                this.dimensionsCanvas.largeur = Math.round(viewport.width);
                this.dimensionsCanvas.hauteur = Math.round(viewport.height);

                //proportion entre le viewport et la largeur de la page => donne l'échelle d'un 1mm à l'écran
                this.echelle =  this.dimensionsCanvas.largeur / this.dimensionsPage.largeur;

                this.canvas.width  = this.dimensionsCanvas.largeur;
                this.canvas.height = this.dimensionsCanvas.hauteur;

                if (this.canvasZones) {
                    this.canvasZones.width  = this.dimensionsCanvas.largeur;
                    this.canvasZones.height = this.dimensionsCanvas.hauteur;
                    let contextZones = this.canvasZones.getContext('2d');
                    contextZones.globalAlpha = 0.05;
                    contextZones.fillStyle = 'black';
                    contextZones.fillRect(
                        0,
                        0,
                        Math.round(this.dimensionsCanvas.largeur),
                        Math.round(this.dimensionsCanvas.hauteur)
                    );
                    contextZones.clearRect(
                        Math.round(this.trimbox.x * this.echelle),
                        Math.round(this.trimbox.y * this.echelle),
                        Math.round(this.trimbox.largeur * this.echelle),
                        Math.round(this.trimbox.hauteur * this.echelle)
                    );
                    contextZones.globalAlpha = 0.35;
                    contextZones.strokeRect(
                        Math.round(this.trimbox.x * this.echelle),
                        Math.round(this.trimbox.y * this.echelle),
                        Math.round(this.trimbox.largeur * this.echelle),
                        Math.round(this.trimbox.hauteur * this.echelle)
                    );

                }
                // fait le rendu de la page dans le context du canvas
                let renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                    intent: "display",
                    //enableWebGL: true,
                    background: 'rgba(255,255,255,0)'
                };

                let renderTask = page.render(renderContext);
                renderTask.promise.then(() => {
                    this.pageEnCoursDeRendu = false;
                    if (this.pageEnAttente !== null) {
                        // New page rendering is pending
                        this.renduPage(this.pageEnAttente);
                        this.pageEnAttente = null;
                    } else {
                        this.renduPDFPret = true;
                    }
                });
            });
        },
        /**
         * Etant donné que nos dimensions PDF partent du bas à gauche, mais la personnalisation du haut à gauche,
         * On doit calculer les coordonnées Y depuis : hauteur totale - ( hauteur du bloc + offset du bloc )
         * L'utilisation du paramètre taille permet donc d'afficher correctement des PDF dont les offset verticaux sont inégaux
         *
         * @param zonebox
         * @param taille
         * @returns {{largeur: number, x: number, y: number, hauteur: number}}
         */
        transposePdfZone (zonebox, taille) {
            return {
                x: Math.round(this.pica2mm(zonebox.x) * 100) / 100,
                y: Math.round(this.pica2mm(taille.height - (zonebox.y + zonebox.height)) * 100) / 100,
                largeur: Math.round(this.pica2mm(zonebox.width) * 100) / 100,
                hauteur: Math.round(this.pica2mm(zonebox.height) * 100) / 100
            }
        },
        zoneEnDehorsDeMediaBox (zone, mediabox) {
            return zone.x < mediabox.x
                || zone.y < mediabox.y
                || zone.largeur > mediabox.largeur
                || zone.hauteur > mediabox.hauteur;
        }
    },
}