<template>
  <div class="perso-codebarre128" :style="styleCalculeCadre">
    <canvas class="dessin" id="dessin"></canvas>
  </div>
</template>

<script>
import CodeBarreMixin from "./mixins/codebarre";
import Code128 from "../../../classes/code128";
//import JsBarcode from "jsbarcode";
export default {
  name: "ElementCodebarre128",
  mixins: [
    CodeBarreMixin
  ],
  computed: {
      typeCode128 () {
          //retourne le type de code 128 sous la forme '' (auto), 'A', 'B', ou 'C'
          return this.value.type.toUpperCase().replace('C128', '');
      }
  },
  watch: {
    echelle (val, old) {
      if (val !== old) this.rendu();
    },
    data (val, old) {
      if (val !== old) this.rendu();
    },
  },
  methods: {
      rendu() {
          if (this.data && typeof this.data === 'string') {
          new Code128(this.data, this.typeCode128).draw(this.canvas, this.cadreEnPixel.largeur,this.cadreEnPixel.hauteur);
//              let nombres_de_barres = new Code128(this.data, this.typeCode128).calcSequences(),
//                  largeur_barre = Math.round(this.cadreEnPixel.largeur / nombres_de_barres);
//              new JsBarcode(this.canvas, this.data, {format: "auto", width: largeur_barre, height: this.cadreEnPixel.hauteur});//.draw(this.canvas, largeur_barre,this.cadreEnPixel.hauteur);
          }
      }
  },
  mounted() {
      this.rendu();
  }
}
</script>

<style scoped lang="scss">
.perso-codebarre128 {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>