export default {
    methods: {
        formatPoids(poids) {
            let poids_arrondi = Math.round(poids * 100) / 100;
            if (poids_arrondi < 1000) return poids_arrondi + 'g';
            poids_arrondi = Math.round(poids_arrondi) / 1000;
            if (poids_arrondi > 15) poids_arrondi = Math.round(poids_arrondi);
            return poids_arrondi + 'Kg';
        }
    }
}
