<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <div class="perso-image" :style="styleCalculeCadre">
        <div class="cadre" @mouseenter="afficheControles" @mouseleave="cacheControles" ref="Cadre">
            <img ref="imageElement" decoding="async" :src="urlImage" :style="styleCalculeImage" @mousedown="startDragging" alt="">
            <div class="zoom-trop-grand" v-if="elementModifie.position.zoom > maxZoom">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on"><v-icon size="24px">{{ mdiAlert }}</v-icon></span>
                    </template>
                    <span>Définition de l'image insuffisante pour ce niveau de zoom: l'impression sera floue</span>
                </v-tooltip>
            </div>

            <div class="zoom-range-container" ref="zoomControl">
                <div class="zoom-range-ui text-white galerie" title="Sélectionner une image">
                    <v-btn text tile x-small dark @click="ouvreGalerieImages" class="btn-galerie"><v-icon size="24px">{{ mdiImage }}</v-icon></v-btn>
                </div>
                <div class="zoom-range-ui text-white">
                    <v-btn text tile x-small dark @click="zoomOut" class="btn-zoom"><v-icon size="24px">{{ mdiMagnifyMinusOutline }}</v-icon></v-btn>
                </div>
                <div class="zoom-range-control">
                    <input type="range" class="zoom-range" :min="zoomMin" :max="limitZoom" step="0.01" v-model.number="elementModifie.position.zoom" :disabled="!estZoomable">
                </div>
                <div class="zoom-range-ui text-white zoom-in">
                    <v-btn text tile x-small dark @click="zoomIn" class="btn-zoom"><v-icon size="24px">{{ mdiMagnifyPlusOutline }}</v-icon></v-btn>
                </div>
                <div class="zoom-range-ui text-white reset" title="Réinitialiser l'image">
                    <v-btn text tile x-small dark @click="reinitialise" class="btn-reset"><v-icon size="24px">{{ mdiRefresh }}</v-icon></v-btn>
                </div>
            </div>
        </div>

        <galerie-images
            :ferme-sur-selection="true"
            v-model="galerieImagesOuverte"
            @selection="appliqueImageGalerie"
        ></galerie-images>
    </div>
</template>
<script>
import ImageMixin from './mixins/image';
import ConvertionUnite from '../../../mixins/conversion/unite';
import GalerieImages from "../../GalerieImages";
//import Api from "../../../api/api";
import {mapGetters} from "vuex";
import { mdiAlert, mdiImage, mdiMagnifyMinusOutline, mdiMagnifyPlusOutline, mdiRefresh } from "@mdi/js";
const ResolutionMinimalePP = 150;

export default {
    name: "ElementImage",
    components: {
        GalerieImages
    },
    mixins: [
        ImageMixin,
        ConvertionUnite
    ],
    data: () => ({
        urlChargeImage:    process.env.VUE_APP_API_URL +  '/media/dl',
//      urlImage: "",
        image: {            //indirectement lié à la taille du cadre, car l'image est ajusté par défaut à sa plus grande dimension pour rester dans le cadre
            largeur: 0,
            hauteur: 0,
            tailleDefinie: false,
        },
        imageEnPixel: {
            largeur: 0,
            hauteur: 0,
        },
        tailleMaxImprimable: {
            largeur: 0,
            hauteur: 0
        },
        isDragging: false,
        positionDansCadre: {    //dépend du centrage horizontal et vertical
            x: 0,
            y: 0
        },
        decalageAvantDeplacement: {
            x: 0,
            y: 0
        },
        mousePosInit: {
            x: 0,
            y: 0
        },
        zoomValue: 1,
        zoomMin: 0.2,

        galerieImagesOuverte: false,
        mdiAlert,
        mdiImage,
        mdiMagnifyMinusOutline,
        mdiMagnifyPlusOutline,
        mdiRefresh
    }),
    props: {
        zoomable: {
            type: Boolean,
            default: true
        },
        deplacable: {
            type: Boolean,
            default: true
        },
        value: {
            type: Object
        }
    },
    computed: {
        ...mapGetters([
            'bearer'
        ]),
        urlImage ()  { //remplace le système de chargement en blob (méthode chargeImage())
            if (!this.elementModifie || !this.elementModifie.data ) return '';
            let identifiant = this.elementModifie.data;
            if (identifiant.indexOf('.') === false) identifiant += '.jpg'; //si c'est un hash, on rajoute '.jpg' pour la mise en cache, laravel le retirera pour ses recherches.
            return this.urlChargeImage + '/' + identifiant + '?Bearer=' + this.bearer;
        },
        limitZoom() {
            return 2 * this.maxZoom;
        },
        pasZoom() {
            return this.maxZoom / 15;
        },
        maxZoom() {
            return Math.max(this.tailleMaxImprimable.largeur / this.elementModifie.position.l, this.tailleMaxImprimable.hauteur / this.elementModifie.position.h);
        },
        styleCalculeImage () { //le style ne doit pas être défini avant chargement sinon cela fausse les calculs de taille d'image
            return (this.image.tailleDefinie === false) ? {
                    top: -10000 + 'px', //on ne peut pas mettre de display none sinon cela empeche d'utiliser clientWidth et clientHeight
                    left: -10000 + 'px'
                } :
                {
                    height: Math.round(this.image.hauteur * this.echelle * this.elementModifie.position.zoom).toString() + 'px',
                    width:  Math.round(this.image.largeur * this.echelle * this.elementModifie.position.zoom).toString() + 'px',
                    top:    Math.round((this.elementModifie.position.offset_y + this.positionDansCadre.y) * this.echelle).toString() + 'px',
                    left:   Math.round((this.elementModifie.position.offset_x + this.positionDansCadre.x) * this.echelle) + 'px'
                }
        },
        //états de l'élément
        imageSelectionnee() {
            return this.elementModifie.data !== ""
        },
        estZoomable () {
            return this.zoomable && this.imageSelectionnee;
        },
        estDeplacable () {
            return this.deplacable && this.imageSelectionnee;
        }
    },
    watch: {
        'elementModifie.position.zoom': {
            handler () {
                this.gereAlignement()
                this.$emit('input', this.elementModifie);
            },
            immediate: true
        },
        'elementModifie.data': {
            handler (val, old) {
                this.image.tailleDefinie = false;
//                this.chargeImage();
                if (old !== val) this.$emit('input', this.elementModifie);
            },
            immediate: true
        }
    },
    methods: {
        zoomOut() {
            if (this.estZoomable) this.elementModifie.position.zoom = Math.max(this.zoomMin, this.elementModifie.position.zoom - this.pasZoom);
        },
        zoomIn() {
            if (this.estZoomable) this.elementModifie.position.zoom = Math.min(this.limitZoom, this.elementModifie.position.zoom + this.pasZoom);
        },
        reinitialise () {
            this.elementModifie.position.zoom = 1;
            this.elementModifie.position.offset_x = 0;
            this.elementModifie.position.offset_y = 0;
        },
        startDragging (e) {
            e.preventDefault();
            if (!this.estDeplacable) return;
            this.isDragging = true;
            this.mousePosInit = {x: e.screenX, y: e.screenY};
            this.decalageAvantDeplacement.x = this.elementModifie.position.offset_x;
            this.decalageAvantDeplacement.y = this.elementModifie.position.offset_y;
        },
        moveDragging (e) {
            if (!this.isDragging) return;
            this.elementModifie.position.offset_x = this.decalageAvantDeplacement.x + ((e.screenX - this.mousePosInit.x) / this.echelle);
            this.elementModifie.position.offset_y = this.decalageAvantDeplacement.y + ((e.screenY - this.mousePosInit.y) / this.echelle);
        },
        endDragging (e) {
            if (!this.isDragging) return;
            this.isDragging = false;
            this.elementModifie.position.offset_x = this.decalageAvantDeplacement.x + ((e.screenX - this.mousePosInit.x) / this.echelle);
            this.elementModifie.position.offset_y = this.decalageAvantDeplacement.y + ((e.screenY - this.mousePosInit.y) / this.echelle);
            this.$emit('input', this.elementModifie)
        },
        afficheControles () {
            if (this.estZoomable) this.$refs.zoomControl.classList.add('visible');
            if (this.estDeplacable) this.$refs.Cadre.classList.add('deplacable');
        },
        cacheControles () {
            this.$refs.zoomControl.classList.remove('visible')
            this.$refs.Cadre.classList.remove('deplacable')

        },
        ouvreGalerieImages () {
            this.galerieImagesOuverte = true;
        },
        appliqueImageGalerie (image) {
            this.reinitialise();
            this.image.tailleDefinie = false;
            this.setImage(image.hash);
        },
        gereAlignement() {
            //switch alignement x
            let alignement_x = this.elementModifie.position.align;
            switch(alignement_x) {
                case "L":
                    this.positionDansCadre.x = 0;
                    break;
                case "R":
                    this.positionDansCadre.x = this.cadre.largeur - (this.image.largeur * this.elementModifie.position.zoom);
                    break;
                default:
                    this.positionDansCadre.x = (this.cadre.largeur - (this.image.largeur * this.elementModifie.position.zoom)) / 2;
            }
            //switch alignement y
            let alignement_y = this.elementModifie.position.valign;
            switch(alignement_y) {
                case "T":
                    this.positionDansCadre.y = 0;
                    break;
                case "B":
                    this.positionDansCadre.y = this.cadre.hauteur - (this.image.hauteur * this.elementModifie.position.zoom);
                    break;
                default:
                    this.positionDansCadre.y = (this.cadre.hauteur - (this.image.hauteur * this.elementModifie.position.zoom)) / 2;
            }
        },
        definieTailleImage (e) {
            if (this.image.tailleDefinie) return;
            let img = e.currentTarget,
                largeurImage = img.clientWidth,     //largeur et hauteur de l'image en pixel
                hauteurImage = img.clientHeight,
                ratioImage = largeurImage / hauteurImage,
                largeurCadre = this.cadreEnPixel.largeur,  //largeur et hauteur du cadre en pixel
                hauteurCadre = this.cadreEnPixel.hauteur,
                largeurImageAdaptee = largeurCadre,
                hauteurImageAdaptee = hauteurCadre,
                ratioCadre = largeurCadre / hauteurCadre;
            if (largeurImage === 0 || hauteurImage === 0) {
                return;
            }
            this.imageEnPixel.largeur = largeurImage;
            this.imageEnPixel.hauteur = hauteurImage;

            this.tailleMaxImprimable.largeur = this.inch2mm(largeurImage / ResolutionMinimalePP);
            this.tailleMaxImprimable.hauteur = this.inch2mm(hauteurImage / ResolutionMinimalePP);
            //calculs pour maintenir l'aspect ratio de l'image sur la dimension qui n'occupe pas la totalité du cadre
            if (ratioCadre > ratioImage) {  //l'image prend la hauteur du cadre et on calcul sa largeur
                // console.debug('Image de '+ largeurImage + 'x' + hauteurImage + ' adaptée en hauteur');
                largeurImageAdaptee = largeurImage * hauteurCadre / hauteurImage;
            } else {                        //l'image prend la largeur du cadre et on calcul sa hauteur
                // console.debug('Image de '+ largeurImage + 'x' + hauteurImage + ' adaptée en largeur');
                hauteurImageAdaptee = hauteurImage * largeurCadre / largeurImage;
            }

            //dimensions de l'image reconverties en millimetres
            this.image.largeur = largeurImageAdaptee / this.echelle;
            this.image.hauteur = hauteurImageAdaptee / this.echelle;
            this.gereAlignement();
            this.image.tailleDefinie = true;
        },
        setImage(identifiant) {
            this.elementModifie.data = identifiant;
        },
/*        chargeImage() { //retourne une promesse après le chargement de la font permettant de chainer un traitement d'initialisation
            Api.getBlob(this.urlChargeImage + '/' + this.elementModifie.data)
                .then(blob => this.urlImage = window.URL.createObjectURL(blob))
                .catch(err => {
                    console.log(err);
                    //this.setImage(''); //on vire l'image si elle ne peut être chargée
                });
        },*/

    },
    mounted () {
        let img = this.$el.querySelector('div.cadre>img');
        //le calcul de dimensions ne doit intervernir qu'une fois l'image chargée
        if (img) {
            img.addEventListener('load', this.definieTailleImage.bind(this));
            img.addEventListener('mousemove', this.moveDragging.bind(this));
            window.addEventListener('mouseup', this.endDragging.bind(this));
        }
    },
    beforeDestroy() {
        let img = this.$el.querySelector('div.cadre>img');
        if (img) {
            img.removeEventListener('load',this.definieTailleImage);
            img.removeEventListener('mousemove',this.moveDragging);
            window.removeEventListener('mouseup',this.endDragging);
        }
    }
}

</script>
<style scoped lang="scss">
.perso-image {
    position: absolute;
    .cadre {
        display: flex;
        flex-direction: column;
        position: relative !important;
        overflow: hidden;
        width: inherit;
        height: inherit;

        img {
            position: absolute !important;      //ces classes CSS ne doivent surtout pas être influencées ou modifiées par
            max-width: fit-content !important;  //les fichiers de style, sinon les proportions de l'image ne sont plus conforme.
            max-height: fit-content !important;
        }
        & .zoom-trop-grand {
            position: absolute;
            left: 10px;
            top: 10px;
            line-height: 24px;
            height: 24px;
            color: red;
            text-shadow: 0 0 5px white;
        }
        & .zoom-range-container {
            display: flex;
            justify-content: space-between;
            position: absolute;
            height: 30px;
            bottom: 0;
            padding: 5px 0;
            width: 100%;
            background-color: rgba(0,0,0,0.5);
            opacity: 0;
            transition: opacity 0.5s linear;
            & .zoom-range-ui {
                flex-shrink: 1;
                margin: 0 5px;
                line-height: 1;
            }
            & .v-btn {
                height:100%;
            }
            .btn-zoom:hover {
                color: lightgray;
                cursor: default;
            }
            .zoom-range {
                height: 20px !important;
                bottom: 0;
            }
        }
        & .zoom-range-container.visible {
            opacity: 1 !important;
            z-index: 1;
            cursor: pointer;
            .zoom-range {
                width: 100%;
                cursor: pointer;
            }
            .btn-zoom:hover {
                color: lightgray;
                cursor: pointer !important;
            }
        }
        & .zoom-range-control {
            flex-grow: 1;
        }
    }
    .cadre.deplacable {
        cursor: grab;
    }
}
//.perso-image:hover {
//  border: 1px solid #000;
//}

.text-white {
    color: #fff;
}
</style>
