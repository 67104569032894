<template>
    <div class="personnalisation">
        <div class="personnalisation-controles" >
            <div class="personnalisation-actions">
                <btn-theme @click.stop="retour">Retour</btn-theme>
            </div>
        </div>
        <div class="zone-travail">
            <h1>Format du fichier déprécié.</h1>
            <div>Merci de contacter nos services pour actualiser celui-ci.</div>
        </div>
    </div>
</template>

<script>
import BtnTheme from "../theme/BtnTheme";
export default {
    name: "PersonnalisationAcroform",
    components: {
        BtnTheme,
    },
    data() {
        return {
        }
    },
    props: {
        article: {
            type: Object,
            required: true,
            validator: (article) => {
                return ('id', 'personnalisation') in article;
            }
        },
    },
    methods: {
        retour () {
            this.$emit('close', null);
        }
    }
}
</script>

<style scoped lang="scss">
$ligne_selectionnee: #bdbdbd;
$couleur_texte: #494f51;
$fond_formulaire: #f5f8fa;
.personnalisation {
    display: flex;
    flex-direction: row;
    width: inherit;
    height: inherit;
    overflow: hidden;
    & .personnalisation-controles {
        background-color: white;
        width: 350px;
        max-width: 350px;
        padding: 0.7em;
        border: 1px solid #eeeeee;
        .contenus {
            margin: 1em 0.7em;
            & .liste-contenu-titre {
                color: $couleur_texte;
                font-weight: 500;
            }
            & .liste-contenu {
                max-height: 400px;
                overflow-y: auto;
                & .ligne-contenu {
                    display: flex;
                    flex-direction: row;
                    background-color: #fafafa;
                    border: 1px solid #cccccc;
                    border-radius: 4px;
                    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    padding: 0.5em;
                    margin: 5px;
                    & .libelle-contenu{
                        flex-grow: 1;
                        color: $couleur_texte;
                        font-weight: 400;
                    }
                    & .actions-contenu {
                        width: 50px;
                    }
                }
                & .ligne-contenu:hover {
                    background-color: $ligne_selectionnee;
                }
                & .ligne-contenu.selectionne{
                    background-color: $ligne_selectionnee;
                }

            }
            .liste-contenu-ajout {
                margin: 0.7em 3em;
                display: flex;
                flex-direction: column;
            }
        }
        & .v-card {
            padding: 1em;
            margin: 1em 0.7em;

            & .contenu-titre {
                color: $couleur_texte;
                font-weight: 500;
                padding-bottom: 1em;
            }
            & .contenu-actions {
                >div {
                    margin: 5px;
                }
            }
        }
        & .libelle-controles {
            text-align: center;
            padding: 0.7em;
            font-weight: 500;
        }
        & .action-controle {
            padding: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        & .personnalisation-actions {
            display: flex;
            flex-direction: row;
            >div {
                margin: 5px;
            }
        }
    }
    & .zone-travail {
        //width: 100%;
        height: inherit;
        background-color: #e6e6e6;
        position: relative;
        flex-grow: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        & .personnalisation-document {
            top: 25px;
            left: 25px;
            position: relative;
            display: flex;
            flex-direction: column;
            box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.33);
            background-color: white;
            .composants {
                position: absolute;
                top:0;
                left: 0;
                width: inherit;
                height: inherit;
                pointer-events:all;
            }
            .pdf {
                position: absolute;
                top:0;
                left: 0;
                pointer-events:none;
            }
            .front {
                z-index: 1;
            }
        }
    }
}
</style>