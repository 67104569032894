const INCH_MM = 25.4;
const INCH_PICA = 72;
export default {
    methods: {

        /**
         * Converti une longueur fournie en mm en pouce

         */
        mm2inch(value) {
            return value / INCH_MM;
        },
        /**
         * Converti une longueur fournie en pouce en millimetre
         */
        inch2mm(value) {
            return value * INCH_MM;
        },

        /**
         * Converti une longueur fournie en pouce en point picas
         */
        inch2pica(value) {
            return value * INCH_PICA;
        },

        /**
         * Converti une longueur fournie en point picas en pouce
         */
        pica2inch(value) {
            return value / INCH_PICA;
        },
        /**
         * Converti une longueur fournie en point pica en millimetre
         */
        pica2mm(value) {
            return this.inch2mm(this.pica2inch(value));
        },
        /**
         * Converti une longueur fournie en millimetre en point pica
         *
         */
        mm2pica(value) {
            return this.inch2pica(this.mm2inch(value));
        },
        /**
         * Converti une longueur fournie en pouce en pixel (la densité en point par pouce est requise)
         *
         */
        inch2pixel(value, density)
        {
            return  value * density;
        },
        /**
         * Converti une longueur fournie en millimetre en pixel (la densité en point par pouce est requise)
         *
         */
        mm2pixel(value, density)
        {
            return this.inch2pixel(this.mm2inch(value), density);
        },

        /**
         * Converti une longueur fournie en pixel en pouce (la densité en point par pouce est requise)
         *
         */
        pixel2inch(pixel, density)
        {
            return pixel / density;
        },

        /**
         * Converti une longueur fournie en pixel en millimetre (la densité en point par pouce est requise)
         *
         */
        pixel2mm(pixel, density)
        {
            return this.inch2mm(this.pixel2inch(pixel, density));
        },

        pixel2pica(pixel, density)
        {
            return this.inch2pica(this.pixel2inch(pixel, density));
        },

        pica2pixel(value, density)
        {
            return this.inch2pixel(this.pica2inch(value), density);
        }
    }
}