/*
 * partie commune à tout les élements quelques soit leur nature
 */
import ConversionUnite from "../../../../mixins/conversion/unite";
const INCH_MM = 25.4;
const INCH_PICA = 72;
const FIELD_FILTER = /({\w+})/g; // recherche les champs de type {champ}
export default {
    mixins: [
        ConversionUnite
    ],
    data: () => ({

        hauteurMinimale: 1, //en millimètre
    }),
    props: {
        value: {          // en parametre on a l'élement avec les définitions de position, de dimension
            type: Object,
            required: true,
        },
        donnees: {          // Source de données
            type: Object,
            required: true
        },
        echelle: {          // l'échelle d'affichage globale pixel => mm
            type: Number,
            required: true
        }
    },
    computed: {
        echellePicas () { // pixel/picas => pixel par point picas
            return this.echelle * INCH_MM / INCH_PICA;
        },
        data () {
            let dataAvantPublipostage = this.value.data;
            switch (typeof dataAvantPublipostage) {
                case "undefined":
                    return '';
                case "string":
                    return this.publipostageChaine(dataAvantPublipostage, this.donnees, this.value.remove_empty_lines);
                case "object":
                    return '';
            }
            return dataAvantPublipostage;
        },
    },
    methods: {
        publipostageChaine(chaine, datasource, retire_lignes_vides) { //transcription de la méthode de publipostage nommée getData() dans Element.php
            if(typeof chaine !== 'string') return chaine;
            let champs = this.parseChamps(chaine);
            for (let i = 0; i < champs.length; i++) {
                let clef = champs[i].replace('{','').replace('}',''),
                    valeur = Object.hasOwnProperty.call(datasource, clef) ? datasource[clef] : '';
                chaine = chaine.replace(champs[i], valeur);
            }
            if (retire_lignes_vides) chaine = chaine.replace(/\n{2,}/, '\n');
            return chaine;
        },
        parseChamps(chaine) { //retoure la liste des champs présents dans une chaine
            let champs = chaine.match(FIELD_FILTER);
            return champs ? champs : [];
        }
    },

}