<template>
  <div class="perso-bloc" :style="styleCalculeCadre">
    <canvas class="dessin"></canvas>
  </div>
</template>

<script>
import TexteMixin from "./mixins/texte";
const REG_ESPACES = /[^\S\xa0]/; //note: utilise double négation => groupe ne correspondant pas un un non-espace ou à chr(160) !
const REG_ALPHANUMERIQUE = /[\w\d]/;
export default {
  name: "ElementBloc",
  mixins: [
    TexteMixin
  ],
  computed: {

    lignes() {
      let texte = this.data,
          caracteres    = [...texte],
          compte        = caracteres.length, //nombre de caractère UTF8
          largeurMax    = this.cadreEnPixel.largeur - (this.padding['L'] + this.padding['R']),
          largeurLigne  = 0,
          dernierSeparateur = -1,
          debutLigne       = [0];
      if (compte === 0) return [texte];

      let largeurCaracteres = caracteres.map(caractere => this.largeurTexteEnPixel(caractere));
      //convertion de l'array des caractères en leurs valeurs UTF8;
      caracteres = caracteres.map(caractere => caractere.charCodeAt(0));

      for(let i = 0; i < compte; i++) {
        let caractere = caracteres[i],
            largeurCaractere = largeurCaracteres[i];

        //note: ceci reprend la méthode exacte de TCPDF pour trouver les caractère sécables ligne 6190 de TCPDF.php
        if ((caractere !== 160) // valeur ascii de l'espace insecable (parce ce caratères est... insécable !)
            && ((caractere === 173) //valeur ascii du caractère shy (trait d'union conditionnel)
                || String.fromCharCode(caractere).match(REG_ESPACES) // si le caractère en cours est un espace
                || ((caractere === 45) // valeur ascii du caractère "-" (tiret) suivi et précédé d'un caractère alphanumérique (donc, pas une énumeration)
                    && (i > 0  && i < compte - 1)
                    && String.fromCharCode(caracteres[i - 1]).match(REG_ALPHANUMERIQUE) // si le caractère précédent n'est pas un espace
                    && String.fromCharCode(caracteres[i + 1]).match(REG_ALPHANUMERIQUE) // si le caractère précédent n'est un epace
                )
            )
        ) dernierSeparateur = i;

        //a partir d'ici on adapte un peu car TCPDF refait deux fois la meme chose dans deux routines pour compter les lignes et découper le texte
        if (((largeurLigne + largeurCaractere) > largeurMax)  || (caractere === 10)) {
          largeurLigne = 0;
          if (caractere === 10) {
            dernierSeparateur = -1;
            if (i < compte - 1) debutLigne.push(i + 1);
          } else if (dernierSeparateur !== -1) { //on utilise le précident caractère sécable
            i = dernierSeparateur;
            dernierSeparateur = -1;
            debutLigne.push(i);
          } else {
            largeurLigne = largeurCaractere;
            if (i < compte - 1) debutLigne.push(i);
          }
        } else {
          largeurLigne += largeurCaractere;
        }
      }

      //découpe le texte suivant les début de lignes determinés
      let tableau = [];
      for(let i = 0; i < debutLigne.length; i++) {
        if(i < debutLigne.length - 1) {
          tableau.push(texte.substring(debutLigne[i], debutLigne[i + 1]).replace("\n",""));
        } else {
          tableau.push(texte.substring(debutLigne[i]).replace("\n",""));
        }
      }
      return tableau;
    }
  },
  watch: {
    echelle () {
      this.rendu();
    },
    data (val, old) {
      if (val !== old) this.rendu();
    },
  },
  methods: {
    rendu() {
      this.nombresLignes = this.lignes.length;
      this.initCanvas();
      // 10/12 Il faut vérifier la présence de border mais aussi que ce ne soit pas 0
      if (this.value.border && this.value.border !== '0') this.context.strokeRect(0,0, this.cadreEnPixel.largeur, this.cadreEnPixel.hauteur);
      this.context.fillStyle = this.fontColor;

      this.context.font= this.fontSizeEnPixel.toString() + "px " + this.nomFont;
      for(let i = 0; i < this.lignes.length; i++) {
        let metriques         = this.context.measureText(this.lignes[i]),
            largeurUtilisable = this.cadreEnPixel.largeur - (this.padding['L'] + this.padding['R']),
            hauteurbloc       = this.hauteurCellule(this.nombresLignes * this.fontSizeEnPixel),
            largeurTexte      = Math.min(largeurUtilisable, metriques['width']);
        let position = this.gereAlignement(largeurTexte, hauteurbloc);
        let y = Math.round(position.Y + (i * this.fontSizeEnPixel * this.ratioHauteurCellule));
        this.context.fillText(this.lignes[i],Math.round(position.X), y, largeurUtilisable);
      }
    },
  },
  mounted () {
    this.rendu();
  }
}
</script>

<style scoped lang="scss">
.perso-bloc {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>