<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <div class="personnalisation">
        <div class="personnalisation-controles" >
            <div v-if="totalPages === 0">Initialisation...</div>
            <div class="libelle-controles" v-if="totalPages > 0">Page&nbsp;{{ page }}&nbsp;/&nbsp;{{totalPages}}</div>
            <div class="action-controle" v-if="totalPages > 1">
                <btn-theme small @click.stop="pagePrecedente" :disabled="page < 2">-</btn-theme><btn-theme small @click.stop="pageSuivante" :disabled="page >= totalPages">+</btn-theme>
            </div>
            <v-select
                v-model="zoom"
                dense
                outlined
                :items="listeZoom"
                item-text="libelle"
                item-value="valeur"
                label="Zoom"
                class="zoom"
            ></v-select>
            <template v-if="modeSelection">
                <div class="contenus">
                    <div class="liste-contenu-titre" v-if="listeContenus.length > 0">Vos contenus mémorisés:</div>
                    <div class="liste-contenu" v-if="listeContenus.length > 0">
                        <div class="ligne-contenu" v-for="(contenu, index) in listeContenusTries" :class="{ selectionne: estSelectionne(contenu) }" @click="appliqueContenu(contenu)" :key="'contenu-' + index">
                            <div class="libelle-contenu">{{ contenu.intitule }}</div>
                            <div class="actions-contenu" v-if="utilisateurPeutEditer">
                                <v-icon size="24px" @click.stop="activeModeEdition(contenu)">{{ mdiFileDocumentEditOutline }}</v-icon>
                                <v-icon size="24px" @click.stop="effaceContenu(contenu)">{{ mdiFileRemoveOutline }}</v-icon>
                            </div>
                        </div>
                    </div>
                    <div class="liste-contenu-ajout" v-if="utilisateurPeutEditer">
                        <v-btn text @click="activeModeEdition(null)"><v-icon>{{ mdiPlus }}</v-icon> Créer un contenu</v-btn>
                    </div>
                </div>
            </template>
            <!-- Edition / Render temps réel -->
            <template v-else-if="modeEdition">
                <v-card>
                    <div class="contenu-titre">Contenu:</div>
                    <v-text-field
                        outlined
                        label="Intitulé du contenu"
                        v-model="nomContenuSelectionne"
                    ></v-text-field>
                    <template v-for="(champ, key) in formulaire">
                        <div class="saisie" :key="'champ-' + key">

                            <template v-if="estTextArea(champ)">
                                <v-textarea
                                    dense
                                    v-model="personnalisation.donnees[key]"
                                    :label="champ.intitule"
                                >
                                </v-textarea>
                            </template>
                            <template v-else-if="estListe(champ)">
                                <v-select
                                    v-model="personnalisation.donnees[key]"
                                    dense
                                    :items="champ.options"
                                    item-text="fieldName"
                                    item-value="fieldName"
                                    :label="champ.intitule"
                                ></v-select>
                            </template>
                            <template v-else-if="estInput(champ)">
                                <v-text-field
                                    dense
                                    v-if="champ.case !== undefined"
                                    :label="champ.intitule"
                                    v-model="personnalisation.donnees[key]"
                                    @input="appliqueFormat(key, champ.case)"
                                ></v-text-field>
                                <v-text-field
                                    dense
                                    v-else
                                    :label="champ.intitule"
                                    v-model="personnalisation.donnees[key]"
                                ></v-text-field>
                            </template>
                        </div>
                    </template>
                    <v-checkbox
                        label="Envoyer en soumission"
                        v-model="doitEtreSoumisEnEmail"
                    ></v-checkbox>

                    <v-text-field
                        v-if="doitEtreSoumisEnEmail"
                        label="E-mail"
                        type="email"
                        :rules="reglesEmail"
                        v-model="soumissionEmail"
                        validate-on-blur
                        ref="submissionEmailInput"
                    ></v-text-field>

                    <v-card-actions class="contenu-actions">
                        <btn-theme @click="annuler"><v-icon>{{ mdiKeyboardBackspace }}</v-icon> Retour</btn-theme>
                        <v-spacer></v-spacer>
                        <btn-theme @click="sauvegardeContenu"><v-icon>{{ mdiContentSave }}</v-icon> Sauver</btn-theme>
                    </v-card-actions>
                </v-card>
            </template>
            <div class="personnalisation-actions" v-if="modeSelection">
                <btn-theme @click="retour">Retour</btn-theme>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>

                <btn-theme @click="sauvegardeArticle" :disabled="!contenuActif">Confirmer</btn-theme>
            </div>
        </div>
        <div class="zone-travail">
            <regle orientation="horizontale" :echelle="echelle" v-model="position.x" :offset="trimbox.x * echelle"  v-show="renduPDFPret"></regle>
            <regle orientation="verticale" :echelle="echelle" v-model="position.y" :offset="trimbox.y * echelle"  v-show="renduPDFPret"></regle>
            <div class="personnalisation-document" :style="styleCadre">
                <!-- 3 objet:
                    - 1 canvas, z-index 1 pour le pdf (pdf),
                    - 2 div contenant les sous objets de personnalisations, l'un devant, l'autre derriere

                    Pourquoi 2 ? parce qu'avec les outils pdf on dispose de la possibilité d'obliter (devant) ou de fusionner (derrière)
                 -->
                <div class="composants" v-if="nombreFonts.length > 0">
                    <!-- v-for des elements arrière plan (images...) le plan d'application sera spécifié dans la structure element -->
                    <template v-for="(element,index) in personnalisation.elements">
                        <element-bloc         v-bind:class="{ 'front': element.layer !=='back' }" v-if="fontsChargees && element.page === page && element.type==='bloc'"         :echelle="echelle" v-model="personnalisation.elements[index]" :donnees="personnalisation.donnees" :listeFonts="listeFonts" :key="index"></element-bloc>
                        <element-texte        v-bind:class="{ 'front': element.layer !=='back' }" v-if="fontsChargees && element.page === page && element.type==='texte'"        :echelle="echelle" v-model="personnalisation.elements[index]" :donnees="personnalisation.donnees" :listeFonts="listeFonts" :key="index"></element-texte>
                        <element-image        v-bind:class="{ 'front': element.layer !=='back' }" v-if="element.page === page && element.type==='image'"        :echelle="echelle" v-model="personnalisation.elements[index]" :donnees="personnalisation.donnees" :deplacable="modeEdition" :zoomable="modeEdition" :key="index"></element-image>
                        <element-codebarre128 v-bind:class="{ 'front': element.layer !=='back' }" v-if="element.page === page && element.type && ['c128','c128a','c128b','c128c'].includes(element.type.toLowerCase())" :echelle="echelle" v-model="personnalisation.elements[index]" :donnees="personnalisation.donnees" :key="index"></element-codebarre128>
                        <element-qrcode       v-bind:class="{ 'front': element.layer !=='back' }" v-if="element.page === page && element.type==='qrcode'"       :echelle="echelle" v-model="personnalisation.elements[index]" :donnees="personnalisation.donnees" :key="index"></element-qrcode>
                        <element-datamatrix   v-bind:class="{ 'front': element.layer !=='back' }" v-if="element.page === page && element.type==='datamatrix'"   :echelle="echelle" v-model="personnalisation.elements[index]" :donnees="personnalisation.donnees" :key="index"></element-datamatrix>
                    </template>
                </div>

                <canvas class="pdf"></canvas>
                <canvas class="zones"></canvas>
            </div>
        </div>
    </div>

</template>

<script>
import BtnTheme from "../theme/BtnTheme";

const PERSONNALISATION_FIELD_KEY    = 'champ';

const FIELD_TYPE_INPUT              = 'input';
const FIELD_TYPE_SELECT             = 'select';
const FIELD_TYPE_TEXTAREA           = 'textarea';
const SELECT_MODE                   = Symbol('SELECT_MODE');
const EDIT_MODE                     = Symbol('EDIT_MODE');

import _ from 'lodash';
import Api from "../../api/api";
import LoaderMixin from "./loader";
import FontMixin from "./personnalisation/mixins/font";
import RenduPDFMixin from './personnalisation/rendupdf';
import ElementImage from "./personnalisation/ElementImage";
import ElementTexte from "./personnalisation/ElementTexte";
import ElementBloc from "./personnalisation/ElementBloc";
import ElementCodebarre128 from "./personnalisation/ElementCodebarre128";
import ElementQrcode from "./personnalisation/ElementQrcode";
import ElementDatamatrix from "./personnalisation/ElementDatamatrix";
import Regle from "./personnalisation/Regle";
import {mapGetters} from "vuex";
import { mdiFileDocumentEditOutline, mdiFileRemoveOutline, mdiPlus, mdiKeyboardBackspace, mdiContentSave } from "@mdi/js";

export default {
    name: "PersonnalisationPdf",
    components: {
        BtnTheme,
        ElementDatamatrix,
        ElementTexte,
        ElementImage,
        ElementBloc,
        ElementCodebarre128,
        ElementQrcode,
        Regle
    },
    mixins: [
        FontMixin,
        LoaderMixin,
        RenduPDFMixin,
    ],
    data: () => ({
        urlTelechargeFichier:     process.env.VUE_APP_API_URL + '/fichier/download',
        urlListeContenu:          process.env.VUE_APP_API_URL + '/personnalisation/contenu/liste',
        urlSauveContenu:          process.env.VUE_APP_API_URL + '/personnalisation/contenu/sauve',
        urlEffaceContenu:         process.env.VUE_APP_API_URL + '/personnalisation/contenu/efface',
        urlNotifieContenu:        process.env.VUE_APP_API_URL + '/personnalisation/contenu/notifie',
        urlRendu:                 process.env.VUE_APP_API_URL + '/personnalisation/article/rendupdf',
        urlCreeArticleTemporaire: process.env.VUE_APP_API_URL + '/article/sauvetemporaire',
        fontsChargees: false,
        listeContenus: [],
        formulaire: null,
        utilisateurPeutEditer: true,
        contenuSelectionne: null,
        nomContenuSelectionne: '',
        backupPersonnalisation: null,   //utilisé par le bouton retour pour revenir à l'etat antérieur
        doitEtreSoumisEnEmail: false,
        soumissionEmail: '',
        reglesEmail: [
            value => !!value || 'Adresse requise.',
            value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Adresse E-mail invalide.'
            },
        ],
        listeZoom : [
            { valeur: 0.5, libelle: '50%'},
            { valeur: 0.75, libelle: '75%'},
            { valeur: 1.00, libelle: '100%'},
            { valeur: 1.50, libelle: '150%'},
            { valeur: 2.00, libelle: '200%'},
            { valeur: 2.50, libelle: '250%'},
            { valeur: 3.00, libelle: '300%'},
            { valeur: 3.50, libelle: '350%'},
            { valeur: 4.00, libelle: '400%'},
            { valeur: 4.50, libelle: '450%'},
            {valeur: -1.00, libelle: 'Plein écran'},
        ],
        zoomConseille: 0,
        personnalisation: {},
        mode: SELECT_MODE,
        position: {
            x: 0,
            y: 0
        },
        mdiFileDocumentEditOutline,
        mdiFileRemoveOutline,
        mdiPlus,
        mdiKeyboardBackspace,
        mdiContentSave
    }),
    props: {
        article: {
            type: Object,
            required: true,
            validator: (article) => {
                let proprietesObligatoires = ['id', 'fichier_id', 'personnalisation'];
                return _.every(proprietesObligatoires, _.partial(_.has, article));
            }
        },
    },
    computed: {
        ...mapGetters([
            'isLogged'
        ]),
        contenuActif () {
            return this.contenuSelectionne && Object.keys(this.contenuSelectionne).length > 0;
        },
        styleCadre () {
            return {
                overflow: "hidden",
                width:  this.dimensionsCanvas.largeur + 'px',
                height: this.dimensionsCanvas.hauteur + 'px'
            }
        },
        nombreFonts () {
            return Object.keys(this.listeFonts);
        },
        modeSelection () {
            return this.mode === SELECT_MODE;
        },
        modeEdition () {
            return this.mode === EDIT_MODE;
        },
        listeContenusTries () {
            return  _.chain(this.listeContenus)
                .sortBy('intitule')
                .value();
        }
    },
    watch: {
        loaded(val) {
            if (val) this.calculZoom();
            if (!this.isLogged) this.activeModeEdition(null);
        }
    },
    methods: {
        estInput (field) {
            return field.type === FIELD_TYPE_INPUT;
        },
        estListe(field) {
            return field.type === FIELD_TYPE_SELECT;
        },
        estTextArea (field) {
            return field.type === FIELD_TYPE_TEXTAREA;
        },
        activeModeEdition (contenu) {
            if (contenu) this.appliqueContenu(contenu);
            if (contenu === null) {
                this.selectionneContenu(null);
            }
            this.backupPersonnalisation = JSON.stringify(this.personnalisation);
            this.mode = EDIT_MODE;
        },
        activeModeSelection (contenu) {
            if (contenu) this.selectionneContenu(contenu);
            this.mode = SELECT_MODE;
        },
        retour () {
            this.$emit('input', false);
            this.$emit('close', null);
        },
        pagePrecedente() {
            if (this.page > 1) this.page--;
        },
        pageSuivante () {
            if (this.page < this.totalPages) this.page++;
        },
        annuler () {
            if(this.backupPersonnalisation) {
                this.personnalisation = JSON.parse(this.backupPersonnalisation);
                this.activeModeSelection();
            }
        },
        chargeFichier () {
            return this.chargementPDF(this.urlTelechargeFichier + '/' + this.article.fichier_id);
        },
        construitFormulaire () {
            //let listeChamps = this.personnalisation.formulaire.map(composant => composant.champ);
            //let donnees = {};

            this.formulaire = _.keyBy(Object.assign([], this.personnalisation.formulaire || {}), PERSONNALISATION_FIELD_KEY);
            return Promise.resolve(true);
        },
        renduPDF () {
            let donnees = Object.assign({}, this.personnalisation);
            donnees.article_id = this.article.id;
            donnees.donnees = this.genereContenu();
            Api.postFile(this.urlRendu, 'preview.pdf', donnees);
        },
        appliqueFormat(key, format) {
            if (format === 'majuscule')
                this.personnalisation.donnees[key] = this.personnalisation.donnees[key].toUpperCase();
            if (format === 'minuscule')
                this.personnalisation.donnees[key] = this.personnalisation.donnees[key].toLowerCase();
            if (format === '+33 (0)?{9d.2}') {
                // Suppression des valeurs non numériques, et du 330 de début
                let tmp = this.personnalisation.donnees[key].replace(/^\+33 \(0\)/g, '').replace(/\D/g,'');
                if (tmp.length) {
                    // Supprime l'éxcedent pour ne garder que les 9 derniers caractères
                    // (Ainsi, un copier collé avec le 0 supprimera le 0)
                    if (tmp.length > 9)
                        tmp = tmp.slice(tmp.length - 9)
                    this.personnalisation.donnees[key] = '+33 (0)' + tmp.replace(/(?!^)(?=(\d{2})+(?=\.|$))/gm," ");
                }
                else
                    this.personnalisation.donnees[key] = '';
            }
        },
        chargeListeContenu() {
            if (!this.isLogged) return Promise.resolve(false);
                return Api.get(this.urlListeContenu + '/' + this.article.id).then(liste => {
                this.$set(this,'listeContenus', liste);
                });
        },
        selectionneContenu (contenu) {
            if (contenu) {
                this.contenuSelectionne = contenu;
                this.nomContenuSelectionne = contenu.intitule;
            } else {
                this.contenuSelectionne = null;
                this.nomContenuSelectionne = '';
                this.videDonnees();
            }
        },
        estSelectionne (contenu) {
            return JSON.stringify(contenu) === JSON.stringify(this.contenuSelectionne);
        },
        appliqueContenu(contenu) { //applique un contenu sur le formulaire et les données modifiables d'images
            if (!contenu) return;
            this.selectionneContenu(contenu);

            let champs = Object.keys(this.formulaire || {}).map(key => key.toLowerCase());
            let elementsImage = this.personnalisation.elements
                .filter(element => element.type === "image");

            let identifiants = elementsImage.map(element => element.identifiant);
            for(let clef in contenu) {
                if(!Object.hasOwnProperty.call(contenu, clef)) continue;
                let donnees = contenu[clef];
                if (champs.includes(clef.toLowerCase())) { // c'est un champ de formulaire
                    this.$set(this.personnalisation.donnees, clef, donnees);
                } else if(identifiants.includes(clef)) { //c'est une image
                    let element = elementsImage.find(element => element.identifiant === clef);
                    if (element) {
                        if (donnees.data !== undefined && donnees.data !== '') element.data = donnees.data;
                        Object.assign(element.position, donnees.position);
                    }
                }
            }
        },
        genereContenu() { //génère un contenu, mixant la saisie du formulaire et les données modifiables des images
            let contenu = Object.assign({}, this.personnalisation.donnees);
            let elementsImage = this.personnalisation.elements.filter(element => element.type === "image");
            for (let i = 0; i < elementsImage.length; i++) {
                let element = elementsImage[i];
                contenu[element.identifiant] = {
                    data: element.data,
                    position: {
                        offset_x: element.position.offset_x,
                        offset_y: element.position.offset_y,
                        zoom: element.position.zoom
                    }
                }
            }
            return contenu;
        },
        sauvegardeContenu() {
            if (!this.isLogged) {
                this.$router.push('/connexion');
            } else {
                let contenu = this.genereContenu();

                contenu.intitule = this.nomContenuSelectionne;

                if (!contenu.intitule)
                    return void this.$store.commit('addSnackMessage', {msg: "Veuillez définir l'intitulé de ce contenu."});

                // verification de l'adresse e-mail si soumission
                if (this.doitEtreSoumisEnEmail) {
                    if (!this.soumissionEmail)
                        return void this.$store.commit('addSnackMessage', {msg: 'Veuillez saisir l\'adresse e-mail de soumission.'});
                    if (!this.$refs.submissionEmailInput.validate()) return void this.$store.commit('addSnackMessage', {msg: 'Veuillez saisir une adresse e-mail de soumission valide.'});
                }

                contenu.article_id = this.article.id;
                Api.post(this.urlSauveContenu, contenu).then(reponse => {  //il s'agit d'un post DIRECTEMENT en JSON car coté Laravel, à l'arrivée, c'est difficile de parser le JSON de certaines clefs et pas d'autres
                contenu.id = reponse.id;
                let contenuDansListe = this.listeContenus.find(contenuDansListe => contenuDansListe.intitule === contenu.intitule);
                //une fois sauvegarder on actualise la liste des contenus
                if (!contenuDansListe) {
                    this.listeContenus.push(contenu);
                } else if (JSON.stringify(contenu) !== JSON.stringify(contenuDansListe)) {
                    Object.assign(contenuDansListe, contenu);
                }
                if (this.doitEtreSoumisEnEmail) this.notifieEmail(contenu.id);
                //et on termine en repassant en mode selection avec le contenu selectionné
                this.activeModeSelection(contenu);
            });
            }
        },
        effaceContenu (contenu) {
            if (confirm("Êtes-vous sûr de vouloir supprimer le contenu \"" + contenu.intitule + "\" ?\nLa récupération ne sera plus possible après confirmation.")) {
                let id = contenu.id;
                this.activeModeSelection();
                this.$nextTick(() => {
                    let data = new FormData();
                    data.append('id', id);
                    Api.post(this.urlEffaceContenu, data).then(() => {
                        if (this.contenuSelectionne.id === contenu.id) this.selectionneContenu(); //deselectionne le contenu si c'est celui qui vient d'être supprimé
                        this.$set(this, 'listeContenus', this.listeContenus.filter(o => o.id !== id));
                    })
                });
            }
        },
        notifieEmail(contenuId) {
            let data = new FormData();
            data.append('contenu_id', contenuId);
            data.append('email', this.soumissionEmail);
            return Api.post(this.urlNotifieContenu, data);
        },
        sauvegardeArticle() {
            let personnalisation = Object.assign({}, this.personnalisation);
            personnalisation.intitule = this.contenuSelectionne.intitule;
            let data = {
                id: this.article.id,
                personnalisation
            };
            if (typeof this.article.assemblage === 'object') data.assemblage = this.article.assemblage;
            Api.post(this.urlCreeArticleTemporaire, data).then(article_tempo => {
                this.$emit('change', article_tempo.reference);
                this.$emit('input', true);
                this.$emit('close');
            }).catch(err => {
                this.$store.commit('addSnackMessage', {msg: err});
            })
        },
        definiDimensions() {
            let zoneTravail = this.$el.querySelector('div.zone-travail');
            this.largeur = zoneTravail.clientWidth - 50;
            this.hauteur = zoneTravail.clientHeight - 50;

        },
        calculZoom() { //défini, à la fin du chargement, un zoom optimal (80% de la surface de travail). Valeur pondérée entre x1 et x4.5
            let pageActuelle = this.pdfDoc.getPage(0),
                taille = pageActuelle.getSize();
            let zoom = Math.min( this.hauteur / taille.height, this.largeur / taille.width )  //prend le plus petit des deux ratios
            zoom = Math.floor( zoom * 0.8 * 2 ) / 2; //calcul un zoom utilisant 80% de la dimension max, arrondi à 0.5 pour être corrélé avec a liste de zooms possible
            this.zoom = Math.min(4.5, Math.max(1.0, zoom)); //limite le zoom en 100 et 450%
        }
    },
    mounted () {
        window.addEventListener('resize', this.definiDimensions);
        this.definiDimensions();
        //défini le canvas de destination de l'apperçu PDF
        let personnalisation = Object.assign({}, this.article.personnalisation);
        if (!Object.hasOwnProperty.call(personnalisation, 'elements')) {
            this.$store.commit('addSnackMessage', {msg: 'Cet article comporte une erreur sur la définition de la personnalisation (ne comporte pas la section "elements"). Veuillez contacter notre support.'});
            this.$emit('close', null);
        }
        personnalisation.donnees = {};
        this.personnalisation = personnalisation;
        this.canvas = this.$el.querySelector('canvas.pdf');
        this.canvasZones = this.$el.querySelector('canvas.zones');
        Promise.all([
            this.chargeListeContenu(),
            this.chargeFichier(),
            this.chargefontsNecessaires(this.personnalisation.elements)
        ]).then(() => {
            this.fontsChargees = true;
            this.construitFormulaire()
        }).catch(err => {
            this.$store.commit('addSnackMessage', {msg: err});
        });

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.definiDimensions);
    }
}
</script>
<style scoped lang="scss">
$ligne_selectionnee: #bdbdbd;
$couleur_texte: #494f51;
$fond_formulaire: #f5f8fa;
.personnalisation {
    display: grid;
    grid-template-columns: 350px auto;
    grid-template-rows: auto;
    width: 100vw;
    overflow: hidden;
    & .personnalisation-controles {
        background-color: white;
        padding: 0.7em;
        border: 1px solid #eeeeee;
        .zoom {
            margin: 15px;
        }
        .contenus {
            margin: 1em 0.7em;
            & .liste-contenu-titre {
                color: $couleur_texte;
                font-weight: 500;
            }
            & .liste-contenu {
                max-height: 400px;
                overflow-y: auto;
                & .ligne-contenu {
                    display: flex;
                    flex-direction: row;
                    background-color: #fafafa;
                    border: 1px solid #cccccc;
                    border-radius: 4px;
                    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    padding: 0.5em;
                    margin: 5px;
                    & .libelle-contenu{
                        flex-grow: 1;
                        color: $couleur_texte;
                        font-weight: 400;
                    }
                    & .actions-contenu {
                        width: 50px;
                    }
                }
                & .ligne-contenu:hover {
                    background-color: $ligne_selectionnee;
                }
                & .ligne-contenu.selectionne{
                    background-color: $ligne_selectionnee;
                }

            }
            .liste-contenu-ajout {
                margin: 0.7em 3em;
                display: flex;
                flex-direction: column;
            }
        }
        & .v-card {
            padding: 1em;
            margin: 1em 0.7em;
            max-height: 60vh;
            overflow-y: auto;
            & .contenu-titre {
                color: $couleur_texte;
                font-weight: 500;
                padding-bottom: 1em;
            }
            & .contenu-actions {
                >div {
                    margin: 5px;
                }
            }
        }
        & .libelle-controles {
            text-align: center;
            padding: 0.7em;
            font-weight: 500;
        }
        & .action-controle {
            padding: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        & .personnalisation-actions {
            display: flex;
            flex-direction: row;
            >div {
                margin: 5px;
            }
        }
    }
    .zone-travail {
        //width: 100%;
        height: calc(100vh - #{$headheight});
        background-color: #e6e6e6;
        position: relative;
        overflow: auto;
        & .personnalisation-document {
            top: 25px;
            left: 25px;
            position: relative;
            display: flex;
            flex-direction: column;
            box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.33);
            background-color: white;
            .composants {
                position: absolute;
                top:0;
                left: 0;
                width: inherit;
                height: inherit;
                pointer-events:all;
            }
            .pdf {
                position: absolute;
                top:0;
                left: 0;
                pointer-events:none;
            }
            .zones {
                z-index: 2;
                position: absolute;
                top:0;
                left: 0;
                pointer-events:none;
            }
            .front {
                z-index: 1;
            }
        }
    }
}
</style>