const NON_APPLICABLE    = 'non_applicable'; // pour les nomenclatures, les articles sans fichiers...
const EN_ATTENTE        = 'en_attente';
const EN_COURS          = 'en_cours';
const CALCUL_EFFECTUE   = 'calcul_effectue';
const FLIPBOOK_PRET     = 'flipbook_pret';
const PRET              = 'pret';

const PRIX_ET_POIDS_CALCULES    = [NON_APPLICABLE, CALCUL_EFFECTUE, FLIPBOOK_PRET, PRET];
const FLIPBOOK_CREE             = [FLIPBOOK_PRET, PRET];
const PREPARATION_NON_TERMINEE  = [EN_ATTENTE, EN_COURS, CALCUL_EFFECTUE, FLIPBOOK_PRET];
const PREPARATION_TERMINEE      = [NON_APPLICABLE, PRET];

//Type de personnalisation
export const TYPE_ACROFORM     = 'acroform';       //le PDF cible est obligatoirement un formulaire adobe

export const TYPE_CUSTOMFORM   = 'customform';     //Remplaçant de acroform.

export const TYPE_PUBLIPOSTAGE = 'publipostage';   //Utilisé pour les publipostages basé sur des fichiers excel ou csv

export const TYPE_TABLE        = 'table';          //Personnalisation dont les données sont dans l'une des tables suivantes:

export const TYPE_ARTICLE      = 'article';        //Personnalisation dont les données sont dans la table article

export const TYPE_CLASSEUR     = 'classeur';       //Personnalisation des classeurs EFC
export const PERSONNALISATION_EDITABLE = [
    TYPE_ACROFORM,
    TYPE_CUSTOMFORM
]
export default {
    // ces méthodes permettent d'avoir des infos sur l'élement spécifiées.
    // utilisées conjointement par les vues Panier et Produit

    methods: {
        //méthodes concernant l'état de préparation
        etatValide (element) {
            return Object.hasOwnProperty.call(element, 'etat_preparation');
        },
        prixEtPoidsCalcules (element) {
            //note de DF: -1 !== expression est plus facile à lire que !!~
            return this.etatValide(element) && -1 !== PRIX_ET_POIDS_CALCULES.indexOf(element.etat_preparation);
        },
        flipbookDisponible (element) {
           return this.etatValide(element) && -1 !== FLIPBOOK_CREE.indexOf(element.etat_preparation);
        },
        preparationNonTerminee (element) {
            return !this.estNomenclature(element)
                && !this.estPicking(element)
                && (this.etatValide(element) && -1 !== PREPARATION_NON_TERMINEE.indexOf(element.etat_preparation));
        },
        preparationTerminee (element) {
            return this.estNomenclature(element)
                || this.estPicking(element)
                || (this.etatValide(element) && -1 !== PREPARATION_TERMINEE.indexOf(element.etat_preparation));
        },

        //méthodes concernant le type de l'élement
        estNomenclature (element) {
            return element && element.categorie === 'nomenclature';
        },
        estPicking(element) {
            return element && element.categorie === 'picking';
        },
        estImprime(element) {
            return element && element.categorie=== 'impression';
        },
        estNumerique(element) {
            return element && element.categorie=== 'numerique';
        },
        estArticleACompleter (element) {
            return element.sansfichier;
        },
        estElementPermanent (element) {
            return !element.temporaire;
        },
        estArticlePersonnalise (element) {              //l'élement comporte une personnalisation
            return element.personnalisation && typeof element.personnalisation === "object" && PERSONNALISATION_EDITABLE.includes(element.personnalisation.type);
        },
        estArticleAPersonnaliser (element) {            //l'élément comporter une personnalisation et elle n'est pas remplie
            return this.estArticlePersonnalise(element) && element.personnalisation.donnees === undefined;
        },
        estNomenclatureEditable (element) {
            return this.estNomenclature(element)
                && element.prix_auto !== 0
                && this.estElementPermanent(element);
        },
        estElementCatalogue(element) {
            return (this.estElementPermanent(element) || this.estNomenclature(element))
                && !this.estArticlePersonnalise(element)
                && !this.estArticleACompleter(element);
        },
        comportePrixATiroirs (element) {
            return element && element.prix_lot && Object.keys(element.prix_lot).length > 0;
        },
        comportePrixAPalier (element) {
            return element && element.prix_palier && Object.keys(element.prix_palier).length > 0;
        },
        prixUnitaireCalcule(element, quantite = null) {
            if (quantite === null) quantite = element.quantite;
            if (this.comportePrixAPalier(element)) {
                const palier = this.palierAtteint(element, Math.max(this.quantiteMinimaleCalculee(element), quantite));
                return palier.prix_unitaire;
            }
            return element.prix
        },
        quantiteMinimaleCalculee(element) {
            if (this.comportePrixAPalier(element))
                return element.prix_palier.reduce((acc, palier) => palier.quantite < acc.quantite ? palier : acc).quantite;
            return element.quantite_minimale;
        },
        palierMinimum(element) {
            return element.prix_palier.reduce((acc, palier) => palier.quantite < acc.quantite ? palier : acc);
        },
        palierAtteint(element, quantite) {
            return element.prix_palier
                .filter(palier => palier.quantite <= quantite)                            // filtre les paliers non atteint
                .reduce((acc, palier) => palier.quantite >= acc.quantite ? palier : acc,  // Sélectionne le palier le plus élevé disponible
                    this.palierMinimum(element));                                         // Prend le palier le plus bas si aucun palier valide
        },
        palierSuivant(element, quantite) {
            return element.prix_palier
                .filter(palier => palier.quantite > quantite)                                // filtre les paliers atteints
                .reduce((acc, palier) => palier.quantite <= acc.quantite ? palier : acc,     // Sélectionne le palier le plus bas disponible
                    { prix: this.palierAtteint(element, quantite).prix, quantite: Infinity } // Prend le dernier palier atteint. Si valeur > palier max, génère un palier de même prix avec quantité infinity
                );
        },
        //composition de la liste déroulante pour les prix à tiroirs
        buildLotSelectItems (prixLot) {
            let units = Object.keys(prixLot);
            let selectItems = [];

            for (let i = 0; i < units.length; i++) {
                let item = {
                    label: `Lot de ${units[i]} (${this.formatMonetaire(prixLot[units[i]])})`,
                    value: Number(units[i])
                }
                selectItems.push(item);
            }

            return selectItems;
        },

        /**
         * Mixin pour gérer les prix, qui peuvent provenir d'une liste de prix, d'un groupe d'articles,
         * ou d'un article à prix unique
         */
        comportePrixMultiples(element) {
            return this.tableauPrix(element) instanceof Array && this.tableauPrix(element).length > 1;
        },
        prixMinimum(element) {
            return Math.min.apply(null, this.tableauPrix(element));
        },
        tableauPrix(element) {
            if (element instanceof Object) {
                // Si on reçoit un groupe d'articles, element.articles existes et est une liste d'elements
                if (element.articles)
                    return element.articles.reduce((acc, val) => {return acc.concat(this.tableauPrix(val));}, []);
                else if (this.comportePrixATiroirs(element))
                    return Object.values(element.prix_lot);
                else if (this.comportePrixAPalier(element))
                    return element.prix_palier.map(a => a.prix_unitaire);
                else if (Object.hasOwnProperty.call(element, "prix_minimum"))
                    return [element.prix_minimum * (element.quantite_minimale || 1)];
                else if (Object.hasOwnProperty.call(element, "prix") && element.prix !== "")
                    return [element.prix];

                return Object.values(element);
            } else if (typeof element === 'number' && isFinite(element))
                return [element];
        },
        quantiteMinimumAuPrixMinimum(element) {
            return this.tableauQuantites(element)[this.tableauPrix(element).indexOf(this.prixMinimum(element))];
        },
        tableauQuantites(element) {
            if (element instanceof Object) {
                // Si on reçoit un groupe d'articles, element.articles existes et est une liste d'elements
                if (element.articles)
                    return element.articles.reduce((acc, val) => {return acc.concat(this.tableauQuantites(val));}, []);
                // TODO : Gérer pour les prix à tiroir
                //else if (this.comportePrixATiroirs(element))
                //    return Object.values(element.prix_lot);
                // TODO : Gérer pour les prix lots
                //else if (this.comportePrixAPalier(element))
                //    return element.prix_palier.map(a => a.prix_unitaire);
                else if (Object.hasOwnProperty.call(element, "prix_minimum"))
                    return element.quantite_minimale || 1;
                else if (Object.hasOwnProperty.call(element, "prix") && element.prix !== "")
                    return element.quantite_minimale || 1;

                return Object.values(element);
            } else if (typeof element === 'number' && isFinite(element))
                return [element];
        }
    }
}
