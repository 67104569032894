<template>
    <div class="perso-texte" :style="styleCalculeCadre">
        <canvas class="dessin"></canvas>
    </div>
</template>
<script>

import TexteMixin from "./mixins/texte";

export default {
    name: "ElementTexte",
    mixins: [
        TexteMixin,
    ],
    watch: {
        echelle (val, old) {
            if (val !== old) this.rendu();
        },
        data (val, old) {
            if (val !== old) this.rendu();
        },
    },
    methods: {
        rendu() {
            this.initCanvas();
            if (parseInt(this.value.border) > 0) this.context.strokeRect(0,0, this.cadreEnPixel.largeur, this.cadreEnPixel.hauteur);
            this.context.fillStyle  = this.fontColor;
            this.context.font       = this.fontSizeEnPixel.toString() + "px " + this.nomFont;
            let metriques           = this.context.measureText(this.data),
                largeurUtilisable   = this.cadreEnPixel.largeur - (this.padding['L'] + this.padding['R']),
                largeurTexte        = Math.min(largeurUtilisable, metriques['width']);
            let position = this.gereAlignement(largeurTexte, this.fontAscent + this.fontDescent);
            //console.log(`Drawing text "${this.data}" with font "${this.nomFont}" (time: ${document.timeline.currentTime})`);
            this.context.fillText(this.data, Math.round(position.X), Math.round(position.Y), this.value.position.stretch === 1 ? largeurUtilisable : largeurTexte);
        },
    },
    mounted () {
        this.rendu();
    }
}
</script>

<style scoped lang="scss">
.perso-texte {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
</style>