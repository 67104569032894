<template>
    <div @dragover="dragOver" @drop="drop" @click="openFileDialog">
        <slot></slot>
    </div>
</template>
<script>
    function stopEvent (e) {
        e.preventDefault();
        e.stopPropagation();
    }
    export default {
        //composant "Racine" d'upload, pouvant être utilsié pour un upload multiple ou unique.
        name: 'ZoneDepot',
        data: () => ({
            files: []
        }),
        props: {
            validator: {
                type: Function,
                default: files => new Promise(r => r(files))
            }
        },
        watch: {
            files: {
                handler: function () {
                    this.$emit('file-submit', this.files)
                },
                deep: true
            }
        },
        methods: {
            setFiles: function (files) {
                this.validator(files).then(files => {
                    this.files = files;
                })
            },
            dragOver: function (e) {
                stopEvent(e);
            },
            drop: function (e) {
                stopEvent(e);
                this.setFiles([...e.dataTransfer.files])
            },
            openFileDialog: function (e) {
                this.$emit('click', e);
                let input = document.createElement('input');
                input.type = 'file';
                input.multiple = true;
                input.click();

                input.addEventListener('change', () => {
                    this.setFiles([...input.files]);
                    input = null; // Pour que le GC passe
                })
            }
        }
    }
</script>
