/*
 * partie commune à tout les élements de type texte (texte, bloc, adresse)
 * - Les images ont besoins de modifier l'element reçu en props pour définir les nouvelles coordonnées de zoom, d'offset et de nom d'image
 * - Le cadre est calculé en fonction de cette version modifiée
 */
import ElementMixin from "./element";
export default {
    mixins: [
        ElementMixin,
    ],
    data: () => ({
        elementModifie: {
            data: '',
            position: {
                x: 0,
                y: 0,
                largeur: 0,
                hauteur: 0,
                zoom: 1,    //ces 3 champs sont obligatoires dans la personnalisation. Leurs absences provoque un crash.
                offset_x: 0,
                offset_y: 0,
            }
        },
    }),
    computed: {
        cadre () { //exprimé en mm, uniquement définie pour rendre plus lisible les variables
            if (Object.keys(this.elementModifie).length < 1) return { x: 0, y: 0, largeur: 0, hauteur: 0};
            let dimensions = {
                x:        this.elementModifie.position.x,
                y:        this.elementModifie.position.y,
                largeur:  this.elementModifie.position.l,
                hauteur:  Math.max(this.elementModifie.position.h, this.hauteurMinimale),
            };
            switch (this.elementModifie.position.calign) {
                case 'M':   // la position y de la cellule est par rapport à son millieu
                    dimensions.y -= (dimensions.hauteur / 2);
                    break;
                case 'B':   // la position y de la cellule est par rapport à son bas
                    dimensions.y -= dimensions.hauteur;
                    break;
                //non pris en charge => les positions relatives au baseline de la font.
                default:
            }
            return dimensions;
        },
        cadreEnPouce () { //exprimé en pouce, pour determiner si la densité de l'image est suffisante
            return {
                largeur:  this.mm2inch(this.elementModifie.position.l),
                hauteur:  this.mm2inch(this.elementModifie.position.h),
            };
        },
        cadreEnPixel () { //exprimé en pixel, uniquement définie pour rendre plus lisible les variables
            return {
                x:        Math.round(this.cadre.x * this.echelle),
                y:        Math.round(this.cadre.y * this.echelle),
                largeur:  Math.ceil(this.cadre.largeur * this.echelle),
                hauteur:  Math.ceil(this.cadre.hauteur * this.echelle),
            };
        },
        styleCalculeCadre () {
            let style = {
                left:   this.cadreEnPixel.x.toString() + 'px',
                top:    this.cadreEnPixel.y.toString() + 'px',
                width:  this.cadreEnPixel.largeur.toString() + 'px',
                height: this.cadreEnPixel.hauteur.toString() + 'px'
            }
            //gestion de la rotation du bloc texte => TCPDF gère la rotation par le coin supérieur gauche, en sens trigonométrique (inverse du CSS)
            if (this.elementModifie.position.angle) {
                style["transform-Origin"] =  "top left";
                style.transform = 'rotate(-' + this.elementModifie.position.angle + 'deg)';
            }
            if (this.elementModifie.border) style.border = '1px solid black';
            return style;
        },
    },
    watch: {
        value: {
            handler (nouveau) {
                if (typeof nouveau !== 'undefined' && JSON.stringify(this.elementModifie) !== JSON.stringify(nouveau)) this.elementModifie = Object.assign({}, nouveau);
            },
            deep: true,
            immediate: true
        }
    },
}